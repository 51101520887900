import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TryitoutComponent } from '../tryitout/tryitout.component';
import { XmlmappingsComponent } from '../xmlmappings/xmlmappings.component';
import { RouteNotesComponent } from '../route-notes/route-notes.component';
import { VarulesComponent } from '../varules/varules.component';
import { FtpfilesComponent } from '../ftpfiles/ftpfiles.component';
import { ToastrService } from 'ngx-toastr';
declare const copyToClipboard: any;
declare const copyToClipboardtable: any;

import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { VbjobsdisplayComponent } from '../vbjobsdisplay/vbjobsdisplay.component';
import { SavevendorComponent } from '../vendor/Savevendor/savevendor.component';
import { ConfigComponent } from '../config/config.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MailComponent } from '../mail/mail.component';
import { NewauditComponent } from '../newaudit/newaudit.component';
import { HttpClient } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']

})
export class CreateComponent implements OnInit {


  createrulejsonvalues = environment.HandlerandRuleCreate.RulePostJson;

  lockdescription: string = '';
  languageMenuTrigger: MatMenuTrigger;
  public reactiveFormsource: FormGroup;
  public reactiveFormtarget: FormGroup;
  public reactiveFormprocess: FormGroup;
  public reactiveFormroute: FormGroup;
  public reactiveFormvbjob: FormGroup;
  public reactiveFormsourceagent: FormGroup;
  public reactiveFormTargetagent: FormGroup;
  public reactiveFormmode: FormGroup;
  public reactiveFormvbagent: FormGroup;
  public reactiveFormmsgtype: FormGroup;
  public reactiveFormroutepolicy: FormGroup;
  public reactiveFormprojectname: FormGroup;
  btncolor = '#17a0b4'
  handlerSpinner: boolean;
  ruleSpinner: boolean;
  SourceAccountList = <any>[];
  TargetAccountList = <any>[];
  ProcessorList = <any>[];
  DeviceList = <any>[];
  isviewpasswordftp: boolean;
  isviewpasswordexftp: boolean;
  selectedsourceaccountid: string = "";
  selectedsourceaccountName: string = "";
  selectedtargetaccountid: string = "";
  selectedtargetaccountName: string = "";
  selectedprocessagentid: string = "";
  selectedprocessdisplayname: string = "";
  selectedprocessapikey: string = "";
  selectedsourceorgid: string = "";
  selectedtargetorgid: string = "";
  envTarget: string = "";
  sourceentitymnemonic: string = "";
  targetentitymnemonic: string = "";
  vendormnemonic: string = "";
  selectedrname: string = ''
  isShown: boolean;
  btnshowtryit: boolean = false;
  deploymentkey: string = '';
  sourceassociatedaccountids: string = '';
  targetassociatedaccountids: string = '';

  protected RouteNameSuggest: any = environment.RouteNameSuggest;
  outboundradioItems: Array<string>;
  vbradioItems: Array<string>;
  modelradio: any = {};
  outboundmodelradio: any = {};
  modelvbclient: any = {};
  modeSelected: string = '';
  rulebuttonshow: boolean = false;
  notfounddata: boolean;
  RouteConfigList: any[];
  formatType: string = "json";
  defaultFormatType: string = 'json';
  configjson: string = '';
  configid: string = '';
  Description: string = '';
  Descriptionflag: boolean = false;
  editorOptions = {
    theme: 'vs', language: this.formatType, readOnly: true, wordWrap: 'on', scrollBeyondLastLine: false
  };
  configagentid: string = "";
  isLoadingagent: boolean;
  SourceAgentNameList: any;
  messageTypeList: any[];
  RoutePolicyList = [
    { name: 'SA..', value: 'SA..' },
    { name: 'SA.SE.', value: 'SA.SE.' },
    { name: 'SA..DE', value: 'SA..DE' },
    { name: 'SA.SE.DE', value: 'SA.SE.DE' }];
  routepolicyselected: string = "Select";
  messagetypeselected: string = "Select";
  TargetAgentNameList: any[];
  selectedsourceagentid: any;
  selectedsourceapikey: any;
  isLoadingTargetagent: boolean;
  selectedtargetapikey: any;
  selectedtargetagentid: any;
  selectedsourceagentname: any;
  selectedtargetagentname: any;
  VendornameBackuplist: any;
  configguids: any;
  RouteConfigBackUpList: any[];
  filteragentlist = <any>[];
  filteragentlistbackup = <any>[];
  messageTypeAllList = <any>[];
  isLoadingstage: boolean = false;
  vpnflag: boolean = false;

  vpncreatedflag: boolean = false;
  configagentapikey: string = '';
  vendorid: any;
  configstatus: any;
  customftp = <CustomFTPDetails>{
    username: '',
    password: '',
    host: '',
    port: '',
    folder: '',
    id: ''
  };
  isLocked: boolean;
  selectRouteNameBackup: any;
  disableGeneratePDFButton: boolean;
  selecteddevice: string = ''
  customfield: boolean
  reqfieldslist: any[] = []
  jobschema: any = ''
  saveresultapiurl: any = ''
  handlerurl: string = ''
  vbagentlist: any = [];
  agentKeyword = 'AgentName';
  selectedvbagentname: string = ''
  selectedvbagentid: string = ''
  notfounddatavbagent: boolean
  savedjobdetails: any = ''
  vbagentlistbackup: any[] = []
  vbFTPFileJob: any = {};
  ipvpnout: string = ''
  portvpnout: any
  hostvpnout: string = ''
  sourcepathvpnout: string = ''
  archivepathvpnout: string = ''
  jobres: any
  destinationinbound: any
  showdestin: boolean = false
  projectnamelist: any[] = []
  projectnameBackuplist: any[] = []
  isLoadingProject: boolean
  selectedprojectId: unknown
  id: string
  selectedprojectname: string
  pdf64responsebody: string
  ftpbase64: string
  filenamedisplay: string
  key: string
  Subject = new FormControl('')
  mainSubject = new FormControl('')
  alltoaddressid: any
  allCcid: any
  htmlContent: string
  inboundDeviceKeyword = 'DeviceName';
  devicenamelList = <any>[];
  DeviceListbackup: any[] = [];
  outboundDeviceKeyword = 'DeviceName';
  defaultjobjson: any
  isoutbounddevice: boolean = false
  isinbounddevice: boolean = false
  updateconfigflagftp: boolean = true;
  updateconfigflagvb: boolean = true;
  array_disableradiobuttons: string[] = [];
  vbfolderpathdisplay: string = '';
  vbfolderpathflag: boolean = false;
  ftpalreadycreated: boolean = false;
  vbjobalreadycreated: boolean = false;
  ftpcreatedflag: boolean = false;
  vbjobcreatedflag: boolean = false;
  handlercreatedflag: boolean = false;
  rulecreatedflag: boolean = false;
  vbjobcreateddisplaylabel: boolean = false;
  ftpcreateddisplaylabel: boolean = false;
  handlercreateddisplaylabel: boolean = false;
  rulecreateddisplaylabel: boolean = false;
  multiprocguid: string
  multiprocname: any
  multiprocfullname: any
  flag: boolean = true
  vpndetails: boolean;
  savpnconfig: any[] = [];
  selectedmessagetype: string = ""
  selectedtargetagentdisplayname: string = ""
  selectedsourceagentdisplayname: string = ""
  selectedsourcename: string = ""
  selectedtargetname: string = ""
  selectedprocessname: string = ""
  oneditftpradio: boolean
  disableexternalftp: boolean
  adtflag: boolean
  dftflag: boolean = false;
  dftcheck: boolean = false;
  connectivitytype: string = ''
  VBJobCurrentStatus: any;
  editorInit(editor: any) {
    if (this.configjson) {
      if (this.configjson.startsWith("<")) {
        this.formatType = "xml";
      } else if (this.configjson.startsWith("{")) {
        this.formatType = "json";
      }
      if (this.defaultFormatType != this.formatType) {
        this.defaultFormatType = this.formatType;
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }
    }
  }
  routeconfigflag: boolean = true;
  routeconfigflagbackup: boolean = true;
  enableconfig: boolean = false;
  additionalinfoflag: boolean = false;
  vendarnamevalue: string = '';
  messagesubtypevalue: string = '';
  additionalinfodataflag: boolean = false;

  public reactiveFormvendar: FormGroup;
  VendarsList: any[];
  isLoadingvendar: boolean = false;
  keywordsearchvendar = "name";
  public reactiveFormAgentFilter: FormGroup;
  isLoadingFilteredValue: boolean;
  selectedInterfacedescription: string = '';
  isShowncustomFTP: boolean = false;
  deploymentsConfigurations: any;
  isflasgSize: Subscription;
  modalconfig: Subscription;
  pipelineselectclass: string = '';
  pagerolepermisions: any = [];

  keywordsearch = 'displayname';
  keywordsearchProject = 'displayname';
  keywordsearchprocessor = 'displayname'
  isLoadingResult: boolean = false;
  RouteNameListPipeline = <any>[];
  selectedrouteId = "";
  selectedrouteDisplayName = "";
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    this.vbradioItems = ['FTPV2', 'VB Client', 'VPN', 'API', 'Device', 'Other', 'None'];
    this.outboundradioItems = ['FTPV2', 'VPN', 'API', 'Device', 'None'];
    if (this.ProcessorAllList.length == 0 && this.dynamicJSON.length == 0) {
      this.InitilizeProcessordata();
      this.setfirstcontrol();
    }
    this.reactiveFormsource = this._fb.group({ name: '' });
    this.reactiveFormtarget = this._fb.group({ namet: '' });
    this.reactiveFormprocess = this._fb.group({ namep: '' });
    this.reactiveFormroute = this._fb.group({ routename: ['', [Validators.maxLength(10)]] });
    this.reactiveFormvbjob = this._fb.group({ namejob: '' });
    this.reactiveFormvendar = this._fb.group({ namevendar: '', vendorid: '' });
    this.reactiveFormsourceagent = this._fb.group({ sourceagent: '' });
    this.reactiveFormTargetagent = this._fb.group({ targetagent: '' });
    this.reactiveFormmode = this._fb.group({ modename: '' });
    this.reactiveFormvbagent = this._fb.group({ vbagentnameauto: '' });
    this.reactiveFormAgentFilter = this._fb.group({ filteragent: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormroutepolicy = this._fb.group({ routepolicy: '' });
    this.reactiveFormprojectname = this._fb.group({ projectname: '' });
    var inputId = JSON.parse(sessionStorage.getItem('pipelineid'));
    if (inputId != null) {
      this.selectedrouteId = inputId;
      this.pipelineselectclass = 'ng-autocompleteselect';
      this.getRouteDetails();
      this.btnshowtryit = false;
      this.copypiprlinrbutton = false;
      this.route.snapshot.paramMap["params"] = {};
    }
    else {
      this.RouteNameSuggest.forEach(element => {
        element.value = '';
      });
    }
  }


  screeHeightforinput = window.innerHeight - 390;
  ngAfterViewInit() {
    document.querySelector(".pipeline_feild input")
      .setAttribute("maxlength", String(199));
  }
  async ngOnInit() {
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
    this.selectedrname = ''
    this.projectnamelist = [];
    this.vpncheck = false;
    this.isftp = false;
    this.UniqueID = "";
    this.adtcheck = false;
    this.modalconfig = this.service.dataRefreshConfig.subscribe(data => {
      if (data) {
        this.getcoreconfigforroute();
      }
    })

    this.isflasgSize = this.service.ConfigJsonFlag.subscribe(data => {
      if (this.service.openedModalConfig == '') {
        const currentModule = this.router.url.split('/')[2];
        if (data["module"] !== 'pipelinelist') {
          if (this.service.configDetails.operation == "Add") {
            this.openconfigpopup("big", 'create', '')
          }
          else {
            this.openconfigpopup("big", 'Edit', this.service.configDetails)
          }
        }
      }

    })

    this.GetMessageTypes('msgtype');
    this.getVendorname();
    this.deploymentsConfigurations = await this.getdeploymentconfiguration();
    if (sessionStorage.getItem('pipelineid') !== null) {
      sessionStorage.removeItem('pipelineid');
    }
    if (this.ProcessorAllList.length == 0 && this.dynamicJSON.length == 0) {
      await this.InitilizeProcessordata();
      if (this.UniqueID == 0) {
        await this.setfirstcontrol();
      }
    }
  }

  copyToClip(data, message: any) {
    if (data != '' && data != undefined) {
      copyToClipboard(data);
      this.toastr.success(message + ' Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.toastr.warning(message + 'Invalid', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  copyToClipSE(id, name, message: any) {
    let SE
    if (message.includes('Target')) {
      SE = 'TargetName:' + name + '\r\n' + 'TargetID:' + id;
    } else {
      SE = 'SourceName:' + name + '\r\n' + 'SourceID:' + id;
    }
    copyToClipboard(SE);
    this.toastr.success(message + ' Copied', "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });

  }


  getRouteName(event: any) {

    this.RouteNameSuggest.forEach(element => {
      element.value = '';
    });
    var val = event;
    if (val) {
      this.RouteNameSuggest[0]["value"] = val;
      this.suggestedroutename = val;
      if (Number(val.length) + Number(1) > 3) {
        this.isLoadingResult = true;
        var url = "api/Routes/GetByDisplayName?name=" + val + "&version=2";
        this.service.getapiurl(url).subscribe({
          next: data => {
            this.isLoadingResult = false;
            this.RouteNameListPipeline = data as any[];
            this.RouteNameListPipeline.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
            this.selectedrouteDisplayName = val;
            if (data.length == 0) {
              this.notfounddata = true;
            } else {
              this.notfounddata = false
            }
          },
          error: error => {
            this.isLoadingResult = false;
            this.notfounddata = false
            this.toastr.error("Unable to fetch the Pipelines", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        });
      }
    }

  }

  async saveroute(mymodalinterfacedescription) {
    this.popupflag = false;
    let validate = await this.validatedata();
    if (validate) {

      if (this.selectRouteNameBackup != this.reactiveFormroute.value.routename) {
        this.getDuplicateRoutes(mymodalinterfacedescription)
      } else {
        this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
      }

    }
  }
  duplicatepipeline: any[] = []
  async getDuplicateRoutes(mymodalinterfacedescription) {
    var apiname = 'api/Routes/GetDuplicateRoutes?RouteName=' + this.reactiveFormroute.get('routename').value;
    this.ngxUiLoaderService.start();
    let response = await this.service.searchduplgettapi(apiname);
    if (response.length > 0) {

      this.duplicatepipeline = response;
      for (let i = 0; i < this.duplicatepipeline.length; i++) {
        if (this.duplicatepipeline[i]['CORECONFIG'] !== '') {
          let connect = JSON.parse(this.duplicatepipeline[i]['CORECONFIG']);
          this.duplicatepipeline[i]['type'] = connect['ConnectivityType'];
        }
      }

      this.toastr.warning("Pipeline Name Already Exist", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
      $("#duplicateModalRight").modal('show').appendTo('body');
    }
    else {
      this.SaveRouteDetails(mymodalinterfacedescription)
    }
  }

  async SaveRouteDetails(mymodalinterfacedescription) {
    this.ngxUiLoaderService.start();
    if (this.selectedrouteId != '') {
      this.popupflag = false;
    }
    else {
      this.popupflag = true;
    }

    if (this.reactiveFormroute.get('routename').value && !this.reactiveFormroute.get('routename').value.includes('(')) {
      this.selectedrouteDisplayName = this.reactiveFormroute.get('routename').value;
    }

    let validate = await this.validatedata();
    if (validate) {

      if (this.envSourceProxy == undefined || this.envSourceProxy == '' || this.envTarget == undefined || this.envTarget == '') {
        if (this.fromprossorSADA == 'DA') {
          this.envSourceProxy = '';
          if (this.envSourceProxy == undefined || this.envSourceProxy == '') {
            var response = this.deploymentsConfigurations;
            if (response !== undefined || response !== '') {
              this.envSourceProxy = response[this.sourcedeployementkey].SA;
            }
          }
        }
        else if (this.fromprossorSADA == 'SA') {
          this.envTarget = '';
          if (this.envTarget == undefined || this.envTarget == '') {
            var response = this.deploymentsConfigurations;
            if (response !== undefined || response !== '') {
              this.envTarget = response[this.targetdeployementkey].DA
            }
          }
        }
      }
      //SA and DA check before create pipeline
      if (this.envSourceProxy == undefined || this.envSourceProxy == '' || this.envTarget == undefined || this.envTarget == '') {
        this.toastr.warning("Deployment Configurations are not found. Please Configure the Deployment", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        /* VALIDATE PIPELINE BEFORE SAVE START*/

        if (this.selectedsourceaccountid == undefined || this.selectedsourceaccountid == '') {
          if (this.selectedsourceaccountid == '' && this.reactiveFormsource.get('name').value) {
            this.selectedsourceaccountid = this.reactiveFormsource.get('name').value.id;
          }
        }

        if (this.selectedtargetaccountid == undefined || this.selectedtargetaccountid == '') {
          if (this.selectedtargetaccountid == '' && this.reactiveFormtarget.get('namet').value) {
            this.selectedtargetaccountid = this.reactiveFormtarget.get('namet').value.id;
          }
        }

        if (this.selectedprocessagentid == undefined || this.selectedprocessagentid == '') {
          if (this.selectedprocessagentid == '' && this.reactiveFormprocess.get('namep').value) {
            this.selectedprocessagentid = this.reactiveFormprocess.get('namep').value.id;
          }
        }

        const pipelinedata = {
          "SEGUID": this.selectedsourceaccountid,
          "DEGUID": this.selectedtargetaccountid,
          "SOURCEPROXY": this.envSourceProxy,
          "TARGETPROXY": this.envTarget,
          "PAID": this.selectedprocessagentid,
          "UniqueID": this.UniqueID == '' ? 0 : Number(this.UniqueID),
          "SEDeploymentKey": this.sourcedeployementkey,
          "DEDeploymentKey": this.targetdeployementkey
        };

        var validateresponse = await this.service.postapi('api/Routes/PipelineValidate', pipelinedata);
        if (validateresponse['status'] && validateresponse['statusText']) {
          this.ngxUiLoaderService.stop();
          // await this.SaveRouteDetails()
          this.toastr.error("Failed to validate pipeline", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (Number(validateresponse["Numberofrecords"]) > 0) {

            const message = `Pipeline already exists with same Source, Target and Processor.Do you want to continue ?`;
            const dialogData = new ConfirmDialogModel("Confirm Action", message, 'confirm', '');
            const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: "500px", data: dialogData, disableClose: true });

            await dialogRef.afterClosed().subscribe(async dialogResult => {
              if (dialogResult === false) {
                this.ngxUiLoaderService.stop();
                return;
              }
              else {
                if (!this.UniqueID && this.ProcessorType !== undefined && this.ProcessorType !== 'L2L') {

                  this.selectedInterfacedescription = "";
                  this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
                }
                else {
                  this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
                }

              }
            });
          }
          else {
            // comment below  line for copy pipeline need description to be enable
            if ((!this.UniqueID || this.copypipelineflag) && this.ProcessorType !== undefined && this.ProcessorType !== 'L2L') {

              this.selectedInterfacedescription = "";
              this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
            }
            else {
              await this.SaveRouteDetailstoDB();
            }
          }
        }
        /* VALIDATE PIPELINE BEFORE SAVE END*/
      }
    }
    this.ngxUiLoaderService.stop();
  }



  async searchRouteNameCleared(event) {
    if (this.selectedrouteId !== "") {
      await this.Resetalldata();
      await this.setfirstcontrol();
    }
  }

  selectedinterfaceEvent(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.pipelineselectclass = 'ng-autocompleteselect';
    this.getRouteDetails();
  }


  closematmenu() {
    this.languageMenuTrigger.closeMenu();
  }
  goLiveButton() {
    if (this.myFlagForSlideToggle === false) {
      this.toastr.warning('Action Cannot Performed Interface is Inactive', '', {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  LockUnlock() {
    this.ngxUiLoaderService.start();
    this.service.requestUnlock(this.UniqueID, JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'], this.isLocked == true ? 1 : 0, this.isLocked == true ? 1 : 0, this.lockdescription, this.selectedrouteId, this.sourcedeployementkey).subscribe(response => {
      if (response) {
        this.ngxUiLoaderService.stop();
        if (response.status == 'Success') {
          this.getRouteDetails();
          this.lockdescription = "";
          this.languageMenuTrigger.closeMenu();
          this.toastr.success(response.statusmessage, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }

      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.warning(response.statusmessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }, error => {
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
      this.ngxUiLoaderService.stop();
    })
  }

  buildmultiproctitle(title: any, index: number) {
    let prop = title.formcontrolname
    this.multiprocfullname = title.formgroupname.value[prop]
    if (title.formgroupname.value[prop] !== undefined && title.formgroupname.value[prop] !== undefined && title.formgroupname.value[prop] !== '') {
      if (typeof title.formgroupname.value[prop] == 'string') {
        return title.formgroupname.value[prop];
      } else {
        return title.formgroupname.value[prop].displayname;

      }
    }

  }
  copymultiprocguid(value) {
    if (value !== undefined) {
      if (typeof value == "string") {
        let uniname = value.split('(')[1];
        let guid = uniname !== undefined ? uniname.split(')')[0] : '';
        copyToClipboard(guid);
      } else {
        copyToClipboard(value.id);
      }
    }

    this.toastr.success('Processor GUID' + " " + 'Copied', "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
  }

  async getRouteDetails() {
    //for additional info start
    this.handlercreateddisplaylabel = false;
    this.rulecreateddisplaylabel = false;
    this.ftpcreateddisplaylabel = false;
    this.vbjobcreateddisplaylabel = false;
    this.vbjobcreatedflag = false;
    this.ftpcreatedflag = false;
    this.handlercreatedflag = false;
    this.rulecreatedflag = false;
    this.vpndetails = false
    this.isShownorderinbound = false
    //for additional info end
    if (this.modelradio.option != 'FTP' || this.modelradio.option != 'External FTP') {
      this.oneditftpradio = true
    }
    else {
      this.oneditftpradio = false
    }
    this.updateconfigflagftp = true;
    this.updateconfigflagvb = true;
    this.array_disableradiobuttons = [];
    if (this.selectedrouteId != '') {
      var url = "api/Routes/get?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      await this.service.getapiurl(url).subscribe({
        next: async data => {

          if (data["ProcessorType"] != '') {
            this.ProcessorType = data["ProcessorType"];
          }
          if (data["vendor"]) {
            this.reactiveFormvendar.patchValue({
              namevendar: data["vendor"],
              vendorid: data['vendorid']
            });
            this.vendarnamevalue = data["vendor"];
            this.vendorid = data['vendorid']
            this.RouteNameSuggest[3]["value"] = data["vendor"];
            this.vendarmsgdiv = true;
          }
          else {
            this.vendarmsgdiv = false;
          }
          this.handlerflag = false;
          this.ruleflag = false;
          this.notebuttonflag = true;
          this.auditbuttonflag = true;
          this.rulebuttonshow = false;
          this.routeflag = true;
          this.additionalinfoflag = true;

          if (data['IsLocked'] !== undefined && data['IsLocked'] == 'True') {
            this.isLocked = true
          } else {
            this.isLocked = false
          }

          if (data["SEDEPLOYMENTKEY"] != '') {
            this.sourcedeployementkey = data["SEDEPLOYMENTKEY"];
          }

          if (data["DEDEPLOYMENTKEY"] != '') {
            this.targetdeployementkey = data["DEDEPLOYMENTKEY"];
          }

          this.modeSelected = data["mode"] == '' ? 'Test Live' : data["mode"];
          this.reactiveFormmode.patchValue({
            modename: this.modeSelected
          });

          //entity address
          this.sourcetype = data["sourcetype"];
          this.sourceentityaddress1 = data["seaddress1"];
          this.sourceentityaddress2 = data["seaddress2"];
          this.sourceentityaddress = data["seaddress1"] + '' + (data["seaddress2"] == null ? '' : data["seaddress2"]);
          this.sourcecity = data["secity"];
          this.sourcestate = data["sestate"];
          this.sourcezip = data["sezip"];

          this.targettype = data["targettype"];
          this.targetentityaddress1 = data["deaddress1"];
          this.targetentityaddress2 = data["deaddress2"];
          this.targetentityaddress = data["deaddress1"] + '' + (data["deaddress2"] == null ? '' : data["deaddress2"]);
          this.targetcity = data["decity"];
          this.targetstate = data["destate"];
          this.targetzip = data["dezip"];

          this.envSourceProxy = data['sourceagentid'];
          this.modelvbclient.vbclientagnetid = this.envSourceProxy;
          this.envTarget = data['destinationagentid'];
          this.connectivitytype = data['Connectivity'];
          this.myFlagForSlideToggle = data['status'] == "active" ? true : false;

          if (this.selectedrouteId != "") {
            this.UniqueID = data['UniqueID'];
            this.selectedrouteDisplayName = data['displayname'];
            this.selectRouteNameBackup = JSON.parse(JSON.stringify(data['displayname']))
            this.reactiveFormroute.patchValue({
              routename: `${this.selectedrouteDisplayName}`
            });

            this.RouteNameSuggest[0]["value"] = this.selectedrouteDisplayName.split(".")[0].trim().replace('-', '');
          }

          if (data['SENAME'] != '') {
            if (data["SEDEPLOYMENTKEY"] != '') {
              this.sourcedpkeydisplay = data["SEDEPLOYMENTKEY"] + '|';
            }

            this.selectedsourceaccountid = data['SEGUID'];
            this.selectedsourceaccountName = data['SENAME'];
            this.RouteNameSuggest[1]["value"] = this.selectedsourceaccountName.split(" ")[0].trim().replace('-', '');
            this.sourceentitytype = data['SETYPE'];
            this.sourcetypedisplay = data['sourcetype'].charAt(0);

            this.sourcetypedisplayid = '|' + this.selectedsourceaccountid;
            this.selectedsourcename = this.selectedsourceaccountName + `(${this.selectedsourceaccountid})`
            this.reactiveFormsource.patchValue({
              name: this.selectedsourcename
            });
          }

          if (data['DENAME'] != '') {
            if (data["DEDEPLOYMENTKEY"] != '') {
              this.targetdpkeydisplay = data["DEDEPLOYMENTKEY"] + '|';
            }

            this.selectedtargetaccountid = data['DEGUID'];
            this.selectedtargetaccountName = data['DENAME'];
            this.RouteNameSuggest[2]["value"] = this.selectedtargetaccountName.split(" ")[0].trim().replace('-', '');
            this.targetentitytype = data['DETYPE'];
            this.targettypedisplay = data['DETYPE'].charAt(0);;

            this.targettypedisplayid = '|' + this.selectedtargetaccountid;
            this.selectedtargetname = this.selectedtargetaccountName + `(${this.selectedtargetaccountid})`
            this.reactiveFormtarget.patchValue({
              namet: this.selectedtargetname
            });
          }

          if (data['PANAME'] != '') {
            this.selectedprocessapikey = data['PAAPIKEY'];
            this.selectedprocessagentid = data['PAID'];
            this.selectedprocessdisplayname = data['PANAME'];
            this.selectedprocessname = this.selectedprocessdisplayname + `(${this.selectedprocessagentid})`
            this.reactiveFormprocess.patchValue({
              namep: this.selectedprocessname
            });

            this.isShown = true;
            this.btnshowtryit = true;
            this.copypiprlinrbutton = true;
            this.interfacetype = data["INTERFACETYPE"];

            if (this.ProcessorType == 'L2L') {
              this.routeconfigflag = false;
            }

            if (this.interfacetype.toLowerCase() == "outbound" && this.vendarnamevalue.toLowerCase() == 'ellkay') {
              this.rulebuttonshow = true;
              this.isshownellkykeys = true;
              this.handlercreatedflag = true;
              this.outboundmodelradio.option = this.connectivitytype;
              this.array_disableradiobuttons.push("disable");
              let jsonresponse = JSON.parse(data["CORECONFIG"])
              this.modelelk.subscriberkey = jsonresponse[0].subscriberKey;
              this.modelelk.servicekey = jsonresponse[0].serviceKey;
            }
            else {
              this.isshownellkykeys = false;
            }
            if (this.interfacetype.toLowerCase() == "outbound") {
              this.rulebuttonshow = true;
              let jsonresponse
              this.isShownresultoutbound = false;
              if (this.ProcessorType !== 'L2L') {
                if (this.connectivitytype != undefined && this.connectivitytype == "None") {
                  this.outboundmodelradio.option = this.connectivitytype;
                  this.array_disableradiobuttons.push("disable");
                  this.ftpexstingpathdiv = true;
                  this.resultsftpchecknone = true;
                  this.ftpcreatedflag = false;
                  this.ftpcreateddisplaylabel = true;
                }
                else if (this.connectivitytype != undefined && this.connectivitytype == "API") {
                  this.outboundmodelradio.option = this.connectivitytype;
                  this.array_disableradiobuttons.push("disable");
                  this.ftpexstingpathdiv = true;
                  this.apiconnectivity = true;
                }
                if (this.connectivitytype !== "None") {
                  if ((data["CORECONFIG"] === '[null]' || data["CORECONFIG"] === undefined)) {
                    this.toastr.warning("CORECONFIG is null or undefined", "", {
                      timeOut: 4000,
                      positionClass: 'toast-bottom-right'
                    });


                    if (this.connectivitytype == 'FTPV2') {
                      this.ftpcreateddisplaylabel = true;
                      this.ftpcreatedflag = false;
                      this.isShownresultoutbound = true;
                    }
                  }
                  else {
                    jsonresponse = JSON.parse(data["CORECONFIG"]);
                    if (jsonresponse != undefined && jsonresponse.length > 0 && Object.keys(jsonresponse[0]).length > 0) {
                      if ((jsonresponse[0].userName) ||
                        (this.connectivitytype == 'FTP' || this.connectivitytype == 'EXFTPPath' ||
                          this.connectivitytype == 'External FTP'
                        || this.connectivitytype == 'FTPV2'
                        || this.connectivitytype == 'External FTPV2')) {
                        this.outboundmodelradio.option = this.connectivitytype;
                        this.array_disableradiobuttons.push("disable");
                        this.ftpusername = jsonresponse[0].userName;
                        this.ftppassword = jsonresponse[0].password;
                        this.ftphost = "sftp://" + jsonresponse[0].host;
                        this.ftpport = jsonresponse[0].port;
                        this.suffixfromprocessor = jsonresponse[0].suffix;
                        this.sourcepath = jsonresponse[0].sourcePath;
                        this.archivepath = jsonresponse[0].archivePath;
                        this.progresspath = jsonresponse[0].progressPath;
                        this.errorpath = jsonresponse[0].errorPath;
                        this.isShownresultoutbound = true;
                        this.updateconfigflagftp = false;
                        this.remotepathdisplay = jsonresponse[0].remotePath;
                        this.ftpexstingpathdiv = false;
                        this.ftpflag = true;
                        this.ftpalreadycreated = true;
                        this.ftpcreatedflag = true;
                        this.ftpcreateddisplaylabel = true;
                        if (this.outboundmodelradio.option == "External FTP" || this.outboundmodelradio.option == "External FTPV2") {
                          this.disableexternalftp = true;
                          this.customftp.username = jsonresponse[1] !== undefined ? jsonresponse[1].userName : '';
                          this.customftp.password = jsonresponse[1] !== undefined ? jsonresponse[1].password : '';
                          this.customftp.host = jsonresponse[1] !== undefined ? "sftp://" + jsonresponse[1].host : '';
                          this.customftp.port = jsonresponse[1] !== undefined ? jsonresponse[1].port : '';
                          this.customftp.folder = jsonresponse[1] !== undefined ? jsonresponse[1].remotePath : '';
                          this.customftp.archivepath = jsonresponse[1] !== undefined ? jsonresponse[1].archivePath : '';
                          this.customftp.id = jsonresponse[1] !== undefined ? jsonresponse[1].id : '';
                        }
                      } else {
                        this.vpncheck = false
                        this.vpndetails = false
                        this.vpnflag = true;
                      }


                      if (this.connectivitytype == 'VPN') {
                        if (jsonresponse != undefined && jsonresponse.length > 0) {
                          this.ipvpnout = jsonresponse[0]['vpnIp'];
                          this.portvpnout = jsonresponse[0]['vpnPort'];
                          this.hostvpnout = jsonresponse[0]['vpnHost'];
                          this.sourcepathvpnout = jsonresponse[0]['sourcePath']
                          this.archivepathvpnout = jsonresponse[0]['archivePath']
                          this.vpncheck = true
                          this.vpndetails = true
                          this.vpncreatedflag = true
                          this.vpnflag = true;
                        }
                        else {
                          this.vpncreatedflag = false;
                          this.vpncheck = true
                          this.vpndetails = false
                          this.vpnflag = true;
                        }
                      }
                    }

                    else {
                      this.ftpcreatedflag = false;
                      this.ftpalreadycreated = false;
                      this.ftpflag = false;
                      this.ftpexstingpathdiv = true;
                      this.updateconfigflagftp = true;
                      this.toastr.warning("Pipeline is of type Outbound, Save the pipeline to create the FTP user", "", {
                        timeOut: 4000,
                        positionClass: 'toast-bottom-right'
                      });
                    }
                  }
                }
              }
              else {
                this.array_disableradiobuttons.push("disable");
                this.outboundmodelradio.option = "None";
              }
            }
            else {
              this.isShownresultoutbound = false;
            }

            if (this.interfacetype == "Inbound") {
              this.rulebuttonshow = false;
              if (this.connectivitytype != undefined && this.connectivitytype == "None") {
                this.isShownorderinboundvb = false;
                this.modelradio.option = this.connectivitytype;
                this.array_disableradiobuttons.push("disable");
              }
              else if (this.connectivitytype != undefined && this.connectivitytype == "API") {
                this.modelradio.option = this.connectivitytype;
                this.array_disableradiobuttons.push("disable");
              }
              else {
                let jsonresponse = JSON.parse(data["CORECONFIG"]);
                if (jsonresponse != undefined && jsonresponse.length > 0) {
                  if (jsonresponse[0] != undefined && Object.keys(jsonresponse[0]).length > 0 && (this.connectivitytype && this.connectivitytype == "VPN")) {
                    this.modelradio.option = this.connectivitytype;
                    this.array_disableradiobuttons.push("disable");
                    if (jsonresponse != undefined && jsonresponse.length > 0) {
                      this.isShownorderinbound = false
                      this.modelvpn.ip = jsonresponse[0]['vpnIp'];
                      this.modelvpn.port = jsonresponse[0]['vpnPort'];
                      this.modelvpn.host = jsonresponse[0]['vpnHost'];
                      this.modelvpn.vpninputdisabled = true;
                      this.vpncreatedflag = true;
                      this.vpnflag = true;
                      this.showdestin = true
                    }
                    else {
                      this.vpncreatedflag = false;
                      this.vpnflag = false;
                    }
                  }
                  else if (jsonresponse[0] != undefined && Object.keys(jsonresponse[0]).length > 0 && (this.connectivitytype && (this.connectivitytype != "None" && this.connectivitytype != "API"))) {
                    this.vbjobalreadycreated = true;
                    this.vbflag = true;
                    this.modelradio.option = this.connectivitytype;
                    this.array_disableradiobuttons.push("disable");
                    this.vbjobcreateddisplaylabel = true;
                    this.vbjobcreatedflag = true;
                    if (this.modelradio.option == 'Other') {
                      this.vbjobdisplayexftp = true;
                      if (jsonresponse[0] != undefined && Object.keys(jsonresponse[0]).length > 0) {
                        this.ftpisDisabled = true;
                        this.modelvbftp.sourcepath = jsonresponse[0].sourcePath;
                        this.modelvbftp.archivepath = jsonresponse[0].archivePath;
                        this.modelvbftp.progresspath = jsonresponse[0].progressPath;
                        this.modelvbftp.errorpath = jsonresponse[0].errorPath;
                        this.ftpalreadycreated = true;
                      }
                    }
                    else {
                      this.isShownorderinboundvb = true;
                      this.isShownorderinbound = true;
                      this.updateconfigflagvb = false;
                      if (this.modelradio.option == 'VB Client') {
                        var jobdata = await this.getJobName(this.selectedrouteDisplayName);
                        if (jobdata.length > 0) {
                          this.vbfolderpathflag = true;
                          this.vbfolderpathdisplay = jobdata[0].SourcePath.replace("\\source", "").replace(/\//g, "\\");
                        }
                        else {
                          this.vbfolderpathdisplay = '';
                        }
                      }
                      else {
                        this.vbfolderpathflag = false;
                      }
                    }
                  }
                  else {
                    this.modelradio.option = this.connectivitytype;
                    if (this.modelradio.option !== "FTPV2") {
                      this.vbjobcreatedflag = false;
                      this.vbjobalreadycreated = false;
                      this.vbflag = false;
                      this.updateconfigflagvb = true;
                      this.isShownorderinboundvb = false;
                    }
                    if (this.modelradio.option === 'FTPV2') {
                      this.isShownorderinboundftp = false;
                      this.ftpcreatedflag = false;
                      this.ftpalreadycreated = false;
                      this.ftpflag = false;
                      this.ftpcreateddisplaylabel = true;
                    }

                  }


                  if (this.connectivitytype != undefined && this.connectivitytype == "Device") {
                    this.modelradio.option = this.connectivitytype;
                    this.array_disableradiobuttons.push("disable");
                    this.isShownorderinbound = true;
                    let flag = await this.getdevicejobdetails();
                    if (flag) {
                      this.toastr.warning("Pipeline is of type Inbound, Save the pipeline to create the VitalBridge job", "", {
                        timeOut: 4000,
                        positionClass: 'toast-bottom-right'
                      });
                    }
                  }
                  if (this.modelradio.option == 'FTP/VPN' || this.modelradio.option == 'FTP' || this.modelradio.option == 'External FTP' || this.modelradio.option == 'FTPV2'|| this.modelradio.option == 'External FTPV2') {
                    if (jsonresponse[0] != undefined && Object.keys(jsonresponse[0]).length > 0 && jsonresponse[0].userName) {
                      this.ftpusername = jsonresponse[0].userName;
                      this.ftppassword = jsonresponse[0].password;
                      this.ftphost = "sftp://" + jsonresponse[0].host;
                      this.ftpport = jsonresponse[0].port;
                      this.sourcepath = jsonresponse[0].sourcePath;
                      this.archivepath = jsonresponse[0].archivePath;
                      this.progresspath = jsonresponse[0].progressPath;
                      this.errorpath = jsonresponse[0].errorPath;
                      if ((this.modelradio.option == "External FTP"||this.modelradio.option == 'External FTPV2') && jsonresponse.length > 1) {
                        this.disableexternalftp = true
                        this.customftp.username = jsonresponse[1].userName;
                        this.customftp.password = jsonresponse[1].password;
                        this.customftp.host = "sftp://" + jsonresponse[1].host;
                        this.customftp.port = jsonresponse[1].port;
                        this.customftp.folder = jsonresponse[1].remotePath;
                        this.customftp.archivepath = jsonresponse[1].archivePath;
                        this.customftp.id = jsonresponse[1].id;
                      }
                      this.isShownorderinboundftp = true;
                      this.isShownorderinbound = true;
                      //to show only remote site path
                      //we are not holding saparatly value
                      this.remotepathdisplay = jsonresponse[0].remotePath;
                      this.updateconfigflagftp = false;
                      this.ftpflag = true;
                      this.ftpalreadycreated = true;
                      this.ftpcreatedflag = true;
                      this.ftpcreateddisplaylabel = true;
                      this.modelradio.option = this.modelradio.option === 'External FTP' ? "External FTP"
                        : this.modelradio.option == 'FTPV2' ? "FTPV2"
                          :this.modelradio.option == 'External FTPV2' ? "External FTPV2": "FTP";
                    }
                    else {
                      this.ftpalreadycreated = false;
                      this.ftpflag = false;
                      this.updateconfigflagftp = true;
                      this.isShownorderinboundftp = false;
                      this.toastr.warning("Pipeline is of type Inbound, Save the pipeline to create the FTP user", "", {
                        timeOut: 4000,
                        positionClass: 'toast-bottom-right'
                      });
                    }
                  }
                  else {
                    this.ftpalreadycreated = false;
                    this.updateconfigflagftp = true;
                    this.isShownorderinboundftp = false;
                  }
                }
              }
            }
            else {
              this.isShownorderinbound = false;
            }
            if (this.connectivitytype != undefined && this.connectivitytype == "None") {
              this.isShownorderinboundvb = false;
              this.modelradio.option = this.connectivitytype;
              this.array_disableradiobuttons.push("disable");
            }
            else if (this.connectivitytype != undefined && this.connectivitytype == "API") {
              this.modelradio.option = this.connectivitytype;
              this.array_disableradiobuttons.push("disable");
            }

            if (data["RouteProcessorData"] != '') {
              this.dynamicJSON = [];
              data["RouteProcessorData"].forEach(element => {
                let controlname = '';
                let reactiveFormprocess: FormGroup;
                if (this.dynamicJSON.length == 0) {
                  controlname = "prop";
                  reactiveFormprocess = this._fb.group({ ["prop"]: `${element.displayname} ${'(' + element.id + ')'}` });
                }
                else {
                  controlname = "prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1);
                  reactiveFormprocess = this._fb.group({ ["prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1)]: `${element.displayname} ${'(' + element.id + ')'}` });
                }

                let inputcnt: inputField = {
                  data: this.ProcessorAllList,
                  formcontrolname: controlname,
                  formgroupname: reactiveFormprocess,
                  sequence: this.dynamicJSON.length + 1,
                  mapicon: true,
                  islive: element.ISLIVE
                }
                this.dynamicJSON.push(inputcnt);
              });
              if (this.dynamicJSON.length > 0) {
                let exists = this.dynamicJSON.filter(ele => ele.islive === false);
                if (exists.length > 0) {
                  this.routeconfigflag = true;
                }
                else {
                  this.routeconfigflag = false;
                }
              }
            }

          }
          else {
            this.btnshowtryit = false;
            this.isShown = false;
            this.copypiprlinrbutton = false;
          }
          this.RouteNameSuggest[4]["value"] = data['messagetype'];
          if (this.interfacetype == "EllkayOutbound" || this.interfacetype == "Outbound") {
            this.RouteNameSuggest[5]["value"] = 'OutBound';
            this.outboundmodelradio.option = this.connectivitytype;
            this.array_disableradiobuttons.push("disable");
          }

          if (this.interfacetype == "EllkayInbound" || this.interfacetype == "Inbound") {
            this.RouteNameSuggest[5]["value"] = 'InBound';
            if (data["messagetype"].toLowerCase() == 'adt') {
              this.adtcheck = true;
              this.selectedrname = this.selectedrouteDisplayName;
              this.ADTinterfaces(false);
            }
          }
          if (data["messagetype"].toLowerCase() == 'dft') {
            this.DFTinterface(false);
          }



          //set copy tab title
          if (this.modelradio["option"] == 'VB Client') {
            this.copytitle = 'Copy Job Name';
          }
          else if (this.modelradio["option"] == 'FTP/VPN' || this.modelradio["option"] == "FTP" || this.modelradio["option"] == "External FTP") {
            this.copytitle = 'Copy FTP Details';
          }

          //for audit purpose
          if (data['messagetype']) {
            this.messagetype = data['messagetype'];
            this.selectedmessagetype = data['messagetype'];
            this.reactiveFormmsgtype.patchValue({
              msgname: this.messagetype
            });
          }
          if (data['routepolicy']) {
            this.routepolicyselected = data['routepolicy'];
            this.reactiveFormroutepolicy.patchValue({
              routepolicy: this.routepolicyselected
            })
          }
          if (data['senderapikey']) {
            this.selectedsourceagentid = data['senderID'] ? data['senderID'] : '';
            this.selectedsourceagentname = data['senderdisplayname'] ? data['senderdisplayname'] : '';
            this.selectedsourceagentdisplayname = this.selectedsourceagentname + `(${this.selectedsourceagentid})`
            this.reactiveFormsourceagent.patchValue({
              sourceagent: this.selectedsourceagentdisplayname
            });
          }
          if (data['recieverapikey']) {
            this.selectedtargetagentid = data['recieverID'] ? data['recieverID'] : '';
            this.selectedtargetagentname = data['recieverdisplayname'] ? data['recieverdisplayname'] : '';
            this.selectedtargetagentdisplayname = this.selectedtargetagentname + `(${this.selectedtargetagentid})`
            this.reactiveFormTargetagent.patchValue({
              targetagent: this.selectedtargetagentdisplayname
            });
          }

          //loading status of the pipeline start

          //loading status of the pipeline end

          const insertorupdatepipelineentity = {
            "ROUTEID": this.selectedrouteId,
            "ROUTENAME": this.selectedrouteDisplayName,
            "SEGUID": this.selectedsourceaccountid,
            "SENAME": this.selectedsourceaccountName,
            "SETYPE": data['SETYPE'],
            "DEGUID": this.selectedtargetaccountid,
            "DENAME": this.selectedtargetaccountName,
            "DETYPE": data['DETYPE'],
            "SOURCEPROXY": this.envSourceProxy,
            "TARGETPROXY": this.envTarget,
            "ROUTEPOLICY": 'SA.SE.DE',
            "MESSAGETYPE": this.messagetype,
            "PAID": this.selectedprocessagentid,
            "MODIFIERID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
            "STATUS": this.myFlagForSlideToggle == true ? 'active' : 'passive',
            "VERSION": "2",
            "SEDEPLOYMENTKEY": "",
            "DEDEPLOYMENTKEY": "",
            "VENDORNAME": this.vendarnamevalue,
          };
          this.oldvalue = {
            "ROUTENAME": this.selectedrouteDisplayName,
            "STATUS": this.myFlagForSlideToggle == true ? 'active' : 'passive',
            "VENDORNAME": this.vendarnamevalue,
            "MODE": this.modeSelected
          }
          setTimeout(() => {
            this.gethandlerrulestatus();
          }, 1000);
          this.ngxUiLoaderService.stop();
        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Unable to fetch Pipeline details with ID:' + this.selectedrouteId, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }
  }

  async Resetalldata() {
    this.selectedrname = ''
    this.adtcheck = false;
    this.isviewpasswordftp = false
    this.vbagentlist = []
    this.isviewpasswordexftp = false
    this.deviceremoteip = ''
    this.deviceremoteport = ''
    this.reqfieldslist = []
    this.DeviceList = []
    this.reactiveFormprojectname.setValue({ "projectname": '' });
    this.id = ''
    this.selectedprojectId = ''
    this.selectedprojectname = ''
    this.pipelineselectclass = ''
    this.projectnamelist = [];
    this.isLocked = false;
    this.selectedprocessname = ''
    this.selectedsourcename = ''
    this.selectedtargetname = ''
    this.disableexternalftp = false;
    this.selectRouteNameBackup = "";
    this.selectedprocessagentid = '';
    this.selectedsourceaccountid = '';
    this.selectedtargetaccountid = '';
    this.selectedrouteId = "";
    this.UniqueID = '';
    this.selectedrouteDisplayName = "";
    this.selectedsourceaccountName = "";
    this.selectedtargetaccountName = "";
    this.selectedprocessdisplayname = "";
    this.reactiveFormsource.reset();
    this.reactiveFormtarget.reset();
    this.btnshowtryit = false;
    this.reactiveFormvbjob.reset();
    this.selectedprocessapikey = "";
    this.myFlagForSlideToggle = false;
    this.reactiveFormroute.reset();
    this.isShownorderinbound = false;
    this.isShown = false;
    this.isShownresultoutbound = false;
    this.modelelk.subscriberkey = '';
    this.modelelk.servicekey = '';
    this.sourcetypedisplay = '';
    this.sourcetypedisplayid = '';
    this.targettypedisplay = '';
    this.targettypedisplayid = '';
    this.isshownellkykeys = false;
    this.RouteNameSuggest.forEach(element => {
      element.value = '';
    });
    this.remotepathdisplay = '';
    this.sourcepath = '';
    this.archivepath = '';
    this.errorpath = '';
    this.progresspath = '';
    this.ftpusername = '';
    this.ftppassword = '';
    this.ftpport = '';
    this.ftphost = '';
    this.ftpremotepath = '';
    this.interfacetype = '';
    this.selectedjobId = '';
    this.selectedjobName = '';
    this.vbjobname = '';
    this.modelradio = {};
    this.VBClientmatcard = false;
    this.array_disableradiobuttons = [];
    this.updateconfigflagftp = true;
    this.updateconfigflagvb = true;
    this.vbfolderpathdisplay = '';
    this.selectedextensionItems = [];
    this.modelvbftp = {};
    this.ftpisDisabled = false;
    this.vbjobnameftp = '';
    this.vbjobdisplayexftp = false;
    this.oldvalue = '';
    this.newvalue = '';
    this.vendarmsgdiv = false;
    this.vendarnamevalue = '';
    this.reactiveFormvendar.reset();
    this.sourcedpkeydisplay = '';
    this.targetdpkeydisplay = '';
    this.ftpcheckexsting = false;
    this.ftpexistingpath = '';
    this.isftp = false;
    this.ipvpnout = ''
    this.portvpnout = ''
    this.hostvpnout = ''
    this.sourcepathvpnout = ''
    this.archivepathvpnout = ''
    this.pipelineselectclass = '';
    this.ftpexstingpathdiv = false;
    this.copypipelineflag = false;
    this.oldselectedrouteDisplayName = '';
    this.resetflag = false;
    this.copypiprlinrbutton = false;
    this.sourceentityaddress = '';
    this.sourceentityaddress1 = '';
    this.sourceentityaddress2 = '';
    this.sourcecity = '';
    this.sourcestate = '';
    this.sourcezip = '';
    this.sourcetype = '';
    this.targetentityaddress = '';
    this.targetentityaddress1 = '';
    this.targetentityaddress2 = '';
    this.targetcity = '';
    this.targetstate = '';
    this.targetzip = '';
    this.targettype = '';
    this.modeSelected = '';
    this.reactiveFormmode.reset();
    this.rulebuttonshow = false;
    this.auditbuttonflag = false;
    this.notebuttonflag = false;
    this.configid = "";
    this.configjson = "";
    this.routeconfigflag = true;
    this.enableconfig = false;
    this.configagentid = "";
    this.reactiveFormsourceagent.reset();
    this.reactiveFormTargetagent.reset();
    this.SourceAgentNameList = [];
    this.TargetAgentNameList = [];
    this.selectedsourceagentid = "";
    this.selectedtargetagentid = "";
    this.isLoadingagent = false;
    this.isLoadingTargetagent = false;
    this.selectedsourceagentname = "";
    this.selectedtargetagentname = "";
    this.selectedsourceapikey = "";
    this.selectedtargetapikey = "";
    this.messagetypeselected = "";
    this.routepolicyselected = "";
    this.additionalinfoflag = false;
    this.VendarsList = this.VendornameBackuplist;
    this.connectivitytypemsg = '';
    this.reactiveFormroutepolicy.reset();
    this.reactiveFormmsgtype.reset();
    this.modelvpn.ip = "";
    this.modelvpn.port = "";
    this.modelvpn.host = "";
    this.modelvpn.sourcepath = "";
    this.modelvpn.archivepath = "";
    this.destinationinbound = "";
    this.showdestin = false;
    this.modelvpn.vpninputdisabled = false;
    this.vpncreatedflag = false;
    this.selectedInterfacedescription = "";
    this.configagentapikey = "";
    this.apiconnectivity = false;
    this.vendorid = "";
    this.vpndetails = false;
    this.customftp = <CustomFTPDetails>{
      username: '',
      password: '',
      host: '',
      port: '',
      folder: '',
      id: '',
      archivepath: ''
    }
    this.oneditftpradio = false;
    this.outboundmodelradio = {};
    this.vpnflag = false;
    this.vbjobalreadycreated = false;
    this.dftcheck = false;
    this.dftflag = false;
    this.savedjobdetails = "";
  };

  async clearAll() {
    this.connectivitytypemsg = '';
    await this.Resetalldata();
    this.searchSourceNameCleared(event);
    this.searchTargetNameCleared(event);
    await this.Resetalldata();
    await this.setfirstcontrol();
    this.reactiveFormmode.setValue({ "modename": '' });
    this.modeItemsList = [
      {
        id: 'Live',
        name: 'Live',
      },
      {
        id: 'Test Live',
        name: 'Test Live',
      },
      {
        id: 'Hold',
        name: 'Hold',
      }
    ];
    this.reactiveFormmode.patchValue(this.modeItemsList);
    this.router.navigate(['/home/createpipeline']);
  }


  keywordaccount = "LocationName";
  sourceCustomFilter = function (SourceAccountList: any[], query: string): any[] {
    return SourceAccountList;
  };
  async getSourceAccountName(event: any) {
    var valuesa = event;
    if (event.includes(":")) {

      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    this.selectedsourceaccountid = '';
    this.reactiveFormsource.patchValue({ name: event })


    if (valuesa != '' && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      ;
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingSource = true;
      //let response = await this.service.getapi(createSearch);
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedtargetaccountid,
        deploymentkey: this.targetdeployementkey,
        associatedaccountids: this.targetassociatedaccountids
      }
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {

        if (response['status'] && response['statusText']) {
          this.SourceAccountList = [];
          this.notfounddata = true;
          this.isLoadingSource = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.SourceAccountList = response as any[];
            this.SourceAccountList = this.service.transformAlphabetically(this.SourceAccountList, 'LocationName');
            this.SourceAccountList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.SourceAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedsourceaccountid = '';
          this.isLoadingSource = false;
        }
      })


    }
    else {
      this.SourceAccountList = [];
      this.notfounddata = true;
    }
  }
  targetCustomFilter = function (TargetAccountList: any[], query: string): any[] {
    return TargetAccountList;
  };
  async getTargetAccountName(event: any) {
    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    this.selectedtargetaccountid = '';
    this.reactiveFormtarget.patchValue({ namet: event })
    if (valueta != '' && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingTarget = true;
      //let response = await this.service.getapi(createSearch);
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedsourceaccountid,
        deploymentkey: this.sourcedeployementkey,
        associatedaccountids: this.sourceassociatedaccountids
      }
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.TargetAccountList = [];
          this.notfounddata = true;
          this.isLoadingTarget = false;
          this.toastr.error("Unable to fetch Target Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.TargetAccountList = response as any[];
            // this.TargetAccountList = this.service.transformAlphabetically(this.TargetAccountList, 'LocationName');
            this.TargetAccountList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.TargetAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedtargetaccountid = '';
          this.isLoadingTarget = false;
        }
      })


    }
    else {
      this.TargetAccountList = [];
      this.notfounddata = true;

    }
  }

  sourcedpkeydisplay: string = '';
  sourceentitytype: string = '';
  envSourceProxy: string = '';
  sourcetypedisplay: string = '';
  sourcetypedisplayid: string = '';
  sourcedeployementkey: string = '';
  sourceentityaddress: string = '';
  sourceentityaddress1: string = '';
  sourceentityaddress2: string = '';
  sourcecity: string = '';
  sourcestate: string = '';
  sourcezip: string = '';
  sourcetype: string = '';
  sourceentityprimaryid: string = '';
  sourceorgguid: string = '';
  async selectedsourceaccountEvent(item) //source agent selected Event
  {
    this.connectivitytypemsg = '';
    if (this.fromprossorSADA == 'DA') {
      this.envSourceProxy = '';
    }
    this.sourceorgguid = item.orgguid
    if (item.Type == "A" || item.Type == 'E-A') {
      this.sourcetype = "Account";
      this.sourceentityprimaryid = item.entityid;
      this.selectedsourceorgid = item.organizationid;
      this.RouteNameSuggest[1]["value"] = item.mnemonic == '' ? this.selectedsourceaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.mnemonic;
      this.sourceentitymnemonic = item.mnemonic == '' ? this.selectedsourceaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.mnemonic;
    }
    else if (item.Type == "O" || item.Type == 'E-O') {
      this.sourcetype = "Organization";
      this.sourceentityprimaryid = item.organizationid;
      this.selectedsourceorgid = item.organizationid;
      this.RouteNameSuggest[1]["value"] = item.orgmnemonic == '' ? this.selectedsourceaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.orgmnemonic;
      this.sourceentitymnemonic = item.orgmnemonic == '' ? this.selectedsourceaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.orgmnemonic;
    }
    else {
      this.sourcetype = item.Type;
      this.sourceentityprimaryid = item.entityid;
      this.selectedsourceorgid = item.organizationid;
    }
    this.selectedsourceaccountid = item.LocationGUID;
    this.sourceassociatedaccountids = item.associatedaccountids;
    this.selectedsourceaccountName = item.Displayname;
    this.sourcedeployementkey = item.deploymentkey;
    this.sourcedpkeydisplay = item.deploymentkey + '|';

    this.sourceentityaddress = (item.Address1 == null ? '' : item.Address1) + ' ' + (item.Address2 == null ? '' : item.Address2);
    this.sourceentityaddress1 = item.Address1 == null ? '' : item.Address1;
    this.sourceentityaddress2 = item.Address2 == null ? '' : item.Address2;
    this.sourcecity = item.city == null ? '' : item.city;
    this.sourcestate = item.state == null ? '' : item.state;
    this.sourcezip = item.zip == null ? '' : item.zip;
    this.sourceentitytype = item.EntityType;
    this.getDevicelistbyentity();
    if (this.interfacetype == 'Outbound' && this.selectedsourceaccountid && this.vendorid && this.outboundmodelradio['option'] == 'Project') {
      this.getprojectname();
    }
    if (this.envSourceProxy == undefined || this.envSourceProxy == '') {
      var response = this.deploymentsConfigurations;
      if (response == undefined || response == '') {
        this.toastr.warning(item.deploymentkey + ' Deployment Configurations are not found. Please Configure the Deployment', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.envSourceProxy = response[item.deploymentkey].SA;
      }
    }

    this.sourcetypedisplay = item.Type;
    this.sourcetypedisplayid = '|' + this.selectedsourceaccountid;


    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });

    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
    this.array_disableradiobuttons = [];
    this.selecteedvbtype(this.modelradio.option);

    if (this.selectedprocessdisplayname.includes('L2L Result')) {
      this.outboundmodelradio.option = 'Device';
      this.modelradio.option = 'Device';
    }
    else if (this.interfacetype == "Outbound" && this.ProcessorType == 'L2L') {
      //this.array_disableradiobuttons.push("disable");
      this.outboundmodelradio.option = 'None';
    }
    else if (this.interfacetype == "Inbound" && this.ProcessorType == 'L2L') {
      //this.array_disableradiobuttons.push("disable");
      this.modelradio.option = 'None';
    }
  }

  targetdpkeydisplay: string = '';
  targettypedisplay: string = '';
  targetentitytype: string = '';
  targettypedisplayid: string = '';
  targetdeployementkey: string = '';
  targetentityaddress: string = '';
  targetentityaddress1: string = '';
  targetentityaddress2: string = '';
  targetcity: string = '';
  targetstate: string = '';
  targetzip: string = '';
  targettype: string = '';
  targetentityprimaryid: string = '';
  targetorgguid: string = '';
  async selectedtargetaccountEvent(item) //target agent selected Event
  {
    if (this.fromprossorSADA == 'SA') {
      this.envTarget = '';
    }
    this.targetorgguid = item.orgguid;
    if (item.Type == "A" || item.Type == 'E-A') {
      this.targettype = "Account";
      this.targetentityprimaryid = item.entityid;
      this.selectedtargetorgid = item.organizationid;
      this.RouteNameSuggest[2]["value"] = item.mnemonic == '' ? this.selectedtargetaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.mnemonic;
      this.targetentitymnemonic = item.mnemonic == '' ? this.selectedtargetaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.mnemonic;
    }
    else if (item.Type == "O" || item.Type == 'E-O') {
      this.targettype = "Organization";
      this.targetentityprimaryid = item.organizationid;
      this.selectedtargetorgid = item.organizationid;
      this.RouteNameSuggest[2]["value"] = item.orgmnemonic == '' ? this.selectedtargetaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.orgmnemonic;
      this.targetentitymnemonic = item.orgmnemonic == '' ? this.selectedtargetaccountName.split(' ').join('').split('.').join('').split('-').join('').slice(0, 3) : item.orgmnemonic;
    }
    else {
      this.targetentityprimaryid = item.entityid;
      this.targettype = item.Type;
      this.selectedtargetorgid = item.organizationid;
    }
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.Displayname;
    this.targetdeployementkey = item.deploymentkey;
    this.targetassociatedaccountids = item.associatedaccountids;

    this.targetdpkeydisplay = item.deploymentkey + '|';
    this.targetentityaddress = (item.Address1 == null ? '' : item.Address1) + ' ' + (item.Address2 == null ? '' : item.Address2);
    this.targetentityaddress1 = item.Address1 == null ? '' : item.Address1;
    this.targetentityaddress2 = item.Address2 == null ? '' : item.Address2;
    this.targetcity = item.city == null ? '' : item.city;
    this.targetstate = item.state == null ? '' : item.state;
    this.targetzip = item.zip == null ? '' : item.zip;
    this.targetentitytype = item.EntityType;
    this.getDevicelistbyentity();
    if (this.interfacetype == 'Inbound' && this.selectedtargetaccountid && this.vendorid && this.modelradio['option'] == 'Project') {
      this.getprojectname();
    }

    if (this.envTarget == undefined || this.envTarget == '') {
      var response = this.deploymentsConfigurations;
      if (response == undefined || response == '') {
        this.toastr.warning(item.deploymentkey + ' Deployment Configurations are not found. Please Configure the Deployment', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }
      else {
        this.envTarget = response[item.deploymentkey].DA
      }
    }

    this.targettypedisplay = item.Type;
    this.targettypedisplayid = '|' + this.selectedtargetaccountid;

    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });
    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
  }

  ProcessorAllList: any = [];
  async getProcessorName(event: any) {
    this.selectedprocessagentid = '';
    this.isLoadingProcesor = true;
    var apiname = '';
    if (event !== 'pipeline') {
      apiname = "api/Agents/GetByDisplayName?name=" + event + '&agentid=0&type=Processor&filterflag=true';
      this.ProcessorList = this.ProcessorAllList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.ProcessorList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingProcesor = false;
    }
    else {
      apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorList = data as any[];
          this.ProcessorList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}` });
          this.ProcessorAllList = this.ProcessorList;
          this.selectedprocessagentid = '';
          this.isLoadingProcesor = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.notfounddata = true;
          this.isLoadingProcesor = false;
          this.toastr.error("Unable to fetch Processor details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }
  }

  IsOrderInbound: boolean = false;
  IsResultOutbound: boolean = false;
  vbagentid: string = '';
  interfacetype: string;
  ftpremotepath: string = '';
  isshownellkykeys: boolean = false;
  isShownorderinbound: boolean = false;
  isShownorderinboundvb: boolean = false;
  isShownorderinboundftp: boolean = false;
  isShownresultoutbound: boolean = false;
  modelelk: any = {};
  modelvpn: any = {};
  modelapi: any = {};
  vendarmsgdiv: boolean = false;
  ftpexstingpathdiv: boolean = false;
  suffixfromprocessor: string = '*';
  fromprossorSADA: string = '';
  eventtype: number;
  ProcessorType: string = '';
  adtcheck: boolean
  async selectedProcessEvent(item, datarow, mymodal) //Processor selected Event
  {
    if (datarow !== null && datarow.formcontrolname === 'prop') {
      this.oneditftpradio = false
      await this.searchProcesorNameCleared(item, datarow);
      this.selectedprocessagentid = item.id;
      this.selectedprocessdisplayname = item.displayname;
      this.selectedprocessapikey = item.apikey;
      this.ProcessorType = item.processortype;

      this.ngxUiLoaderService.start();
      var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=" + item.id + '&apikey=' + item.apikey;

      var responseconfig = await this.service.getapi(apiname);
      this.ngxUiLoaderService.stop();
      let jsonresponse = JSON.parse(responseconfig.responsebody);
      this.reactiveFormvendar.setValue({ "namevendar": '', "vendorid": '' });
      if (jsonresponse.processorconfig.xslconfig['suffix']) {
        this.suffixfromprocessor = jsonresponse.processorconfig.xslconfig['suffix'];
      }
      else {
        this.suffixfromprocessor = '*';
      }

      if (jsonresponse.processoragentconfig) {
        if (jsonresponse.processoragentconfig['eventtype']) {
          this.eventtype = jsonresponse.processoragentconfig['eventtype'];
        }
        if (jsonresponse.processoragentconfig['messagetype']) {
          this.messagetype = jsonresponse.processoragentconfig['messagetype'];

          this.envSourceProxy = jsonresponse.processoragentconfig['sourceagentid'];
          this.envTarget = jsonresponse.processoragentconfig['destinationagentid'];

          //SA and DA values not clearing after changing entity
          if (this.envSourceProxy == '' && this.envTarget == '') {
            this.fromprossorSADA = 'SADA';
          }
          else if (this.envSourceProxy == '') {
            this.fromprossorSADA = 'DA';
          }
          else if (this.envTarget == '') {
            this.fromprossorSADA = 'SA';
          }
          this.RouteNameSuggest[4]["value"] = this.messagetype;

          this.interfacetype = jsonresponse.processorconfig["interfacetype"];
          if (this.interfacetype == "EllkayOutbound") {
            this.isshownellkykeys = true;
          }
          else {
            this.isshownellkykeys = false;
          }
          //vendar and message subtype div show
          if (this.interfacetype == "EllkayInbound") {
            this.vendarmsgdiv = false;
          }
          else {
            this.vendarmsgdiv = true;
          }

          if (this.interfacetype == "EllkayInbound") {
            this.vendarmsgdiv = false;
          }


          if (this.interfacetype == "EllkayOutbound" || this.interfacetype == "Outbound") {
            this.RouteNameSuggest[5]["value"] = 'OutBound';
          }

          if (this.interfacetype == "EllkayInbound" || this.interfacetype == "Inbound") {
            this.RouteNameSuggest[5]["value"] = 'InBound';
          }
        }
      }
      else {
        this.isshownellkykeys = false;
        this.messagetype = '';
      }

      let myArray = [];

      this.RouteNameSuggest.forEach(element => {
        if (element.value != '') {
          myArray.push(element.value);
        }
      });

      this.reactiveFormroute.patchValue({
        routename: myArray.map(element => element).join(".")
      });
      this.suggestedroutename = this.reactiveFormroute.value.routename;
      if (this.interfacetype == "Outbound" && this.ProcessorType == 'L2L') {
        this.outboundmodelradio.option = 'None';
      }
      else if (this.interfacetype == "Inbound" && this.ProcessorType == 'L2L') {
        this.modelradio.option = 'None';
      }
      else if (this.interfacetype == "Outbound") {
        this.ftpexstingpathdiv = true;
        this.ftpcheckexsting = false;
      }
      else {
        this.ftpexstingpathdiv = false;
      }
    }

    if (this.modeSelected != '') {
      this.modeItemsList = [
        {
          id: 'Live',
          name: 'Live',
        },
        {
          id: 'Test Live',
          name: 'Test Live',
        },
        {
          id: 'Hold',
          name: 'Hold',
        }
      ];
      this.reactiveFormmode.patchValue(this.modeItemsList);
    }
    if (this.messagetype.toLowerCase() == 'adt' && mymodal != '') {
      this.adtcheck = true;
      this.modalService.open(mymodal, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }
  }

  ftprouteremotepath: string;
  popupflag: boolean = false;
  oldvalue: any = '';
  newvalue: any = '';
  lisfiledownloadurl: string = '';
  notebuttonflag: boolean = false;
  auditbuttonflag: boolean = false;

  vpnconn: any;
  routeflag: boolean = false;
  vbflag: boolean = false;
  ftpflag: boolean = false;
  handlerflag: boolean = false;
  ruleflag: boolean = false;
  interfaceflag: boolean = false;
  vpncheck: boolean = false;
  newvaluestring: string = ''
  oldvaluestring: string = ''
  param: any = ''
  selectedrstatus: string = ''
  selectedroutename: string = ''
  //Click on Add button event triggered
  async SaveRouteDetailstoDB() {
    try {
      this.handlercreateddisplaylabel = false;
      this.rulecreateddisplaylabel = false;
      this.ftpcreateddisplaylabel = false;
      this.vbjobcreateddisplaylabel = false;

      this.ngxUiLoaderService.start();
      var daconfigjson: any = {}

      if (this.interfacetype == "Inbound" && this.modelradio["option"] == 'VB Client') {
        this.envSourceProxy = this.modelvbclient.vbclientagnetid;
      }

      this.UniqueID = this.UniqueID == undefined ? '' : this.UniqueID;

      /* Get agent configuration */
      var deploymentleveconfigs = this.deploymentsConfigurations;
      /* Get the lisfiledownloadurl start */

      if (this.interfacetype == "Outbound" || this.interfacetype == "EllkayOutbound" || this.interfacetype == "ResultOutbound") {
        var lisfiledownloadurl = deploymentleveconfigs[this.targetdeployementkey];
        this.lisfiledownloadurl = lisfiledownloadurl.apidetails["lisfiledownloadurl"];
      }
      else {
        var lisfiledownloadurl = deploymentleveconfigs[this.sourcedeployementkey];
        this.lisfiledownloadurl = lisfiledownloadurl.apidetails["lisfiledownloadurl"];
      }

      const processorlist = [];
      this.dynamicJSON.forEach(element => {
        if (element.mapicon) {
          if (element.formgroupname.controls[element.formcontrolname].value.UniqueID == undefined) {
            var objdata = this.ProcessorAllList.find(x => x.id == element.formgroupname.controls[element.formcontrolname].value.split('(')[1].split(')')[0]);
            processorlist.push({
              "UniqueID": objdata.UniqueID,
              "Guid": objdata.id,
              "sequence": element.sequence
            });
          }
          else {
            processorlist.push({
              "UniqueID": element.formgroupname.controls[element.formcontrolname].value.UniqueID,
              "Guid": element.formgroupname.controls[element.formcontrolname].value.id,
              "sequence": element.sequence
            });
          }
        }
        else {
          processorlist.push({
            "UniqueID": element.formgroupname.controls[element.formcontrolname].value.UniqueID,
            "Guid": element.formgroupname.controls[element.formcontrolname].value.id,
            "sequence": element.sequence
          });
        }
      });
      let vendarnameobj = this.VendarsList.find(t => t['Id'] == this.vendorid);
      let connobj = {};
      let vpnobj = {};
      if (vendarnameobj['name'].toLowerCase() == 'ellkay' && (this.interfacetype.toLowerCase() == 'outbound' || this.interfacetype.toLowerCase() == 'ellkayoutbound')) {
        connobj = {
          "subscriberKey": this.modelelk.subscriberkey,
          "serviceKey": this.modelelk.servicekey,
        }
      }
      else if (this.modelradio["option"] != undefined && this.modelradio["option"].toLowerCase() == 'vpn') {
        connobj = {
          "vpnIp": this.modelvpn.ip,
          "vpnPort": this.modelvpn.port,
          "vpnHost": this.modelvpn.host,
        }
      }
      else if (this.outboundmodelradio["option"] != undefined && this.outboundmodelradio['option'].toLowerCase() == 'vpn') {
        connobj = {
          "vpnIp": this.ipvpnout,
          "vpnPort": this.portvpnout,
          "vpnHost": this.hostvpnout,
        }
      }
      else if (this.modelradio["option"] != undefined && this.modelradio["option"].toLowerCase() == 'external ftp') {
        connobj = {
          "externalFtpUsername": this.customftp.username,
          "externalFtpPassowrd": this.customftp.password,
          "externalFtpHost": this.customftp.host,
          "externalFtpPort": this.customftp.port,
          "externalFtpFolderPath": this.customftp.folder,
          "externalFtpArchivePath": this.customftp.archivepath,
        };
      }
      else if (this.outboundmodelradio["option"] != undefined && this.outboundmodelradio["option"].toLowerCase() == 'external ftp') {
        connobj = {
          "externalFtpUsername": this.customftp.username,
          "externalFtpPassowrd": this.customftp.password,
          "externalFtpHost": this.customftp.host,
          "externalFtpPort": this.customftp.port,
          "externalFtpRemotePath": this.customftp.folder,
        };
      }

      const insertorupdatepipelineentity = {
        "projectId": this.id,
        "connectivitytype": this.interfacetype.toLowerCase().includes('ellkay') ? 'API' : this.interfacetype == 'Inbound' ? this.modelradio['option'] : this.outboundmodelradio['option'],
        "ROUTEID": this.copypipelineflag == true ? '' : this.selectedrouteId,
        "ROUTENAME": this.selectedrouteDisplayName.trimLeft().trimRight(),
        "SEGUID": this.selectedsourceaccountid,
        "SENAME": this.selectedsourceaccountName,
        "SETYPE": this.sourceentitytype,
        "DEGUID": this.selectedtargetaccountid,
        "DENAME": this.selectedtargetaccountName,
        "DETYPE": this.targetentitytype,
        "SOURCEPROXY": this.selectedvbagentid != '' ? this.selectedvbagentid : this.envSourceProxy,
        "TARGETPROXY": this.envTarget,
        "ROUTEPOLICY": 'SA.SE.DE',
        "MESSAGETYPE": this.messagetype,
        "PAID": this.selectedprocessagentid,
        "MODIFIERID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "STATUS": this.myFlagForSlideToggle == true ? 'active' : 'passive',
        "VERSION": "2",
        "DAJOSNCONFIG": JSON.stringify(daconfigjson),
        "SEDEPLOYMENTKEY": this.sourcedeployementkey,
        "DEDEPLOYMENTKEY": this.targetdeployementkey,
        "VENDORID": this.UniqueID == '' ? ((this.vendorid == '' || this.vendorid == undefined) ? this.reactiveFormvendar.get('vendorid').value : this.vendorid) :
          (this.vendorid == '' || this.vendorid == undefined) ? this.reactiveFormvendar.get('vendorid').value : this.vendorid,
        "copyinterfacemappingsflag": this.copyinterfacemappings,
        "copypipelineflag": this.copypipelineflag,
        "UniqueID": Number(this.UniqueID),
        "seaddress1": this.sourceentityaddress1,
        "seaddress2": this.sourceentityaddress2,
        "secity": this.sourcecity,
        "sestate": this.sourcestate,
        "sezip": this.sourcezip,
        "deaddress1": this.targetentityaddress1,
        "deaddress2": this.targetentityaddress2,
        "decity": this.targetcity,
        "destate": this.targetstate,
        "dezip": this.targetzip,
        "filedownloadurl": this.lisfiledownloadurl,
        "MODE": this.modeSelected,
        "sourcetype": this.sourcetype,
        "targettype": this.targettype,
        "Processorlist": processorlist,
        "interfacetype": this.interfacetype == 'EllkayOutbound' ? 'Outbound' : this.interfacetype == 'EllkayInbound' ? 'Inbound' : this.interfacetype,
        "description": this.selectedInterfacedescription,
        "assigneduserid": (this.UniqueID === 0 || this.UniqueID === '' || this.copypipelineflag == true) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : '',
        "VENDORNAME": vendarnameobj['name'],
        "connectivitydetails": connobj,
      };

      var responseroutedata = await this.service.postapi('api/Routes/SavePipeline', insertorupdatepipelineentity);
      if ((responseroutedata['status'] && responseroutedata['statusText']) || responseroutedata['statusText'] === "Unknown Error") {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Unable to create Pipeline", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.routeflag = false;
      }
      else {
        console.log("s R: " + responseroutedata["ROUTEID"] );
        if (responseroutedata["ROUTEID"] != '') {
          this.dynamicJSON.forEach(element => {
            element.mapicon = true;
          });
          this.auditbuttonflag = true;
          this.notebuttonflag = true;

          this.routeflag = true;
          this.RouteNameSuggest.forEach(element => {
            element.value = '';
          });

          this.selectedrouteId = responseroutedata["ROUTEID"];
          this.selectedroutename = responseroutedata["ROUTENAME"];
          this.UniqueID = responseroutedata['UniqueID'];
          let vendarnameobj = this.VendarsList.find(t => t['Id'] == responseroutedata['VENDORID']);
          this.selectedrstatus = responseroutedata["STATUS"];
          if ((this.interfacetype == "Inbound" || this.interfacetype == "EllkayInbound") && Object.keys(this.modelradio).length != 0) {
            if (this.modelradio["option"] == 'Other') {
              this.updateconfigflagftp = false;
              this.isShownorderinboundftp = false;
              await this.createvbjobexistingftp();//create job 
            }
            else if (this.modelradio["option"] == 'VB Client') {
              this.updateconfigflagftp = false;
              this.isShownorderinboundftp = false;
            }
            else if (this.modelradio['option'] == 'Device') {
              this.devicejobconfigid = await this.createdevicejob();
            }
            else if (this.modelradio['option'] == 'Project') {
              let ftpprojobj = {
                "projectcontextid": this.id.toString(),
                "routeUniqueid": this.UniqueID,
                "routeMessageType": this.messagetype,
                "routeSourceAgent": this.envSourceProxy,
                "suffix": null,
                "createdBy": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
                "direction": this.interfacetype,
                "context": "Pipeline",
                "connectivityType": "FTP"
              }
              this.saveprojectftp(ftpprojobj);
            }
            else if (this.modelradio["option"] != 'API') {
              if (this.updateconfigflagftp) {
                if (this.ftpusername == '' || this.ftppassword == '' || this.ftpport == '' || this.ftpremotepath == '' || this.ftphost == ''
                  || this.ftpusername == undefined || this.ftppassword == undefined || this.ftpport == undefined || this.ftpremotepath == undefined || this.ftphost == undefined
                ) {
                  if (this.modelradio['option'] == 'FTP' || this.modelradio['option'] == 'External FTP' || this.modelradio['option'] == 'FTPV2'||this.modelradio['option'] == 'External FTPV2') {
                    await this.createFTP();
                  }

                }
              }

              if (this.updateconfigflagvb) {
                //create vb job and get job id and set
                if (this.selectedjobName == '' && this.selectedjobId == '') {
                  if (this.modelradio["option"] == 'VB Client') {
                    await this.getftppaths(this.modelvbclient.vbclientpath + '/');
                  }
                  else if (this.modelradio["option"] == 'FTP') {
                    var createjobresponse = await this.createvbjob();
                    await this.SaveVbJobConfig(createjobresponse)
                  }
                }
                else {
                  this.isShownorderinbound = true;
                  this.isShownorderinboundvb = true;
                  if (this.modelradio["option"] == 'VB Client') {
                    this.vbfolderpathflag = true;
                    this.vbfolderpathdisplay = this.modelvbclient.vbclientpath;
                  }
                  else {
                    this.vbfolderpathflag = false;
                  }
                }
              }


              if (this.modelradio["option"] == 'FTP' || this.modelradio["option"] == 'External FTP' || this.modelradio["option"] == 'Project' || this.modelradio["option"] == 'FTPV2'||this.modelradio["option"] == 'External FTPV2') {
                this.isShownorderinboundftp = true;
              }
              if (this.modelradio["option"] == 'External FTP'||this.modelradio["option"] == 'External FTPV2' && (this.UniqueID == 0 || this.UniqueID != '')) {
                await this.savecustomFTPDetails();
                if (this.modelradio["option"] == 'External FTP') {
                  await this.SaveUploadOrDownloadVBJob('FTP5FileWatchDownload', this.modelradio["option"]);
                }
              }


              this.array_disableradiobuttons.push("disable");
            }

            //create handlers and rule
            if (this.popupflag && (this.UniqueID == 0 || this.UniqueID != '')) {
              await this.createhandlerandrule(deploymentleveconfigs, this.targetdeployementkey, false);
            }
            else {
              await this.updatehandlerandrule(deploymentleveconfigs, this.targetdeployementkey);
            }
            if (this.messagetype.toLowerCase() == 'adt') {
              await this.ADTinterfaces(true);
            }
            if (this.messagetype.toLowerCase() == 'dft') {
              this.DFTinterface(true);
            }


          }
          else if (this.interfacetype == "Outbound" || this.interfacetype == "EllkayOutbound") {
            console.log("s " + this.outboundmodelradio["option"]);
            if (Object.keys(this.outboundmodelradio).length != 0) {
              if (this.outboundmodelradio["option"] == 'FTP' || this.outboundmodelradio["option"] == 'Other'
                || this.outboundmodelradio["option"] == 'VPN' || this.outboundmodelradio["option"] == 'External FTP'
                || this.outboundmodelradio["option"] == 'FTPV2'
                || this.outboundmodelradio["option"] == 'External FTPV2') {
                if (this.updateconfigflagftp) {
                  this.array_disableradiobuttons.push("disable");
                  var deployementconfig = deploymentleveconfigs[this.sourcedeployementkey];
                  await this.getrandamusernamepassword();
                  // call create FTP credentials and update ftp config
                  const postentity: any = {
                    "UserName": this.ftpusername,
                    "Password": this.ftppassword,
                    "RemoteSitePath": this.selectedrouteDisplayName, //will create ftp folder with route name
                    "FTPpath": this.modelvbftp.sourcepath,
                    "Deploymentkey": this.targetdeployementkey,
                    "ContextId": this.UniqueID,
                    "AgentId": this.envSourceProxy,
                    "ConnectivityType": this.outboundmodelradio["option"],
                    "Context": "pipeline",
                    "ModifierID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
                    "Suffix": this.suffixfromprocessor,
                    "FolderWatchFlag": true,
                    "MessageTypes": this.messagetype,
                    "Directions": this.interfacetype,
                    "TypeFlag": "Interface"
                  }
                  var ftpresponse = await this.service.postapi("api/FTP/CreateAndSaveFTP", postentity);
                  if (ftpresponse['statusmessage'] == 'Success') {
                    this.ftpflag = true;
                    this.ftpalreadycreated = false;
                    this.ftpusername = ftpresponse['UserName'];
                    this.ftphost = ftpresponse['ServerDomain'];
                    this.ftpport = ftpresponse['Port'];
                    if (this.outboundmodelradio["option"] == 'FTPV2') {
                      this.ftppassword = ftpresponse['Password']
                      this.ftphost = "sftp://" + ftpresponse['ServerDomain'];
                    }
                    this.remotepathdisplay = this.outboundmodelradio["option"] == 'Other' ? ftpresponse['FullPath'] : ftpresponse['RemoteSitePath'];
                    await this.getftppaths(ftpresponse['FullPath']);


                    this.ftpexstingpathdiv = false;
                    this.isShownorderinbound = false;
                    this.isShownresultoutbound = true;

                    if (this.outboundmodelradio["option"] == 'VPN') {
                      //  await this.SaveVbLisnerSenderJob('Sender', this.sourcepath, this.archivepath);
                      //await this.createvpnconnectivity(this.sourcepath, this.archivepath);
                    }
                  }
                  else {
                    this.ftpflag = false;
                    this.toastr.error('Pipeline saved successfully but failed to create FTP user', "", {
                      timeOut: 4000,
                      positionClass: 'toast-bottom-right'
                    });
                    return;
                  }

                  if (this.outboundmodelradio["option"] == 'External FTP' || this.outboundmodelradio["option"] == 'External FTPV2') {
                    await this.savecustomFTPDetails();
                    if (this.outboundmodelradio["option"] == 'External FTP') {
                      await this.SaveUploadOrDownloadVBJob('FTP5FileWatchUpload', this.outboundmodelradio["option"]);
                    }
                    this.updateconfigflagftp = false;
                    this.ftpflag = true;
                  }
                  this.updateconfigflagftp = false;
                }
              }
              else if (this.outboundmodelradio['option'] == 'Project') {
                let ftpprojobj = {
                  "projectcontextid": this.id.toString(),
                  "routeUniqueid": this.UniqueID,
                  "routeMessageType": this.messagetype,
                  "routeSourceAgent": this.envSourceProxy,
                  "suffix": null,
                  "createdBy": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
                  "direction": this.interfacetype,
                  "context": "Pipeline",
                  "connectivityType": "FTP"
                }
                this.saveprojectftp(ftpprojobj);
              }
            }
            //create handlers and rule
            if (this.popupflag && (this.UniqueID == 0 || this.UniqueID != '')) {
              await this.createhandlerandrule(deploymentleveconfigs, this.sourcedeployementkey, false);
            }
            else {
              await this.updatehandlerandrule(deploymentleveconfigs, this.sourcedeployementkey);
            }
            if (this.messagetype.toLowerCase() == 'dft') {
              this.DFTinterface(true);
            }
          }

          this.vbjobname = this.selectedjobName;
          this.btnshowtryit = true;

          /*copypipeline start*/
          this.copypipelineflag = false;
          this.oldselectedrouteDisplayName = '';
          this.resetflag = false;
          this.copypiprlinrbutton = true;
          /*copypipeline end*/

          if (this.ProcessorType.toLowerCase() == 'general') {
            await this.gethandlerrulestatus();
          }
          this.isShown = true;
          if (this.popupflag) {
            let filterdata = { "sequence": 1 }
            this.open(filterdata);
          }
          else {
            this.ngxUiLoaderService.stop();
          }

          //notifications start
          if (this.routeflag) {
            this.toastr.success('Pipeline saved Successfully with Pipeline Id : ' + this.selectedrouteId, "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
          }

          if (!this.routeflag) {
            this.toastr.error('Unable to save the Pipeline', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
          }

          if (this.popupflag && this.ProcessorType != 'L2L' && this.ProcessorType.toLowerCase() != 'splitter' && this.ProcessorType.toLowerCase() != 'batch') {
            if (this.handlerflag) {
              this.handlercreateddisplaylabel = true;
              this.handlercreatedflag = true;
              this.toastr.success('Handler created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
            }

            if (!this.handlerflag) {
              this.handlercreateddisplaylabel = true;
              this.handlercreatedflag = false;
              this.toastr.error('Failed to create Handler', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
            }

            if (this.interfaceflag) {
              this.toastr.success('Interface Details created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
            }
            if (!this.interfaceflag) {
              this.toastr.error('Interface Details not created ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
            }
          }
          if (this.interfacetype == 'Inbound' || this.interfacetype == 'EllkayInbound') {
            if (this.modelradio["option"] !== 'None' && this.modelradio["option"] !== 'API' && this.modelradio["option"] !== 'VPN' && this.modelradio["option"] !== 'Device') {
              if (this.vbflag && !this.vbjobalreadycreated && this.modelradio["option"] != 'FTPV2' && this.modelradio["option"] != 'External FTPV2') {
                this.toastr.success('VitalBridge Job created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
                this.vbjobcreateddisplaylabel = true;
                this.vbjobcreatedflag = true;
              }
              if (this.modelradio["option"] !== 'Other' && this.modelradio["option"] !== 'Device') {
                if (this.ftpflag && !this.ftpalreadycreated && this.modelradio["option"] != 'FTPV2' && this.modelradio["option"] != 'External FTPV2') {
                  this.toastr.success('FTP User created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
                  this.ftpcreateddisplaylabel = true;
                  this.ftpcreatedflag = true;
                }
                if (this.ftpflag && !this.ftpalreadycreated && (this.modelradio["option"] == 'FTPV2'||this.modelradio["option"]=='External FTPV2')) {
                  this.toastr.success('SFTP User created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
                  this.ftpcreateddisplaylabel = true;
                  this.ftpcreatedflag = true;
                }
                if (!this.ftpflag) {
                  this.ftpcreateddisplaylabel = true;
                  this.ftpcreatedflag = false;
                  this.toastr.error('Failed to create FTP User', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
                }
              }
              if (!this.vbflag && this.modelradio["option"] != 'FTPV2' && this.modelradio["option"] != 'External FTPV2') {
                this.vbjobcreateddisplaylabel = true;
                this.vbjobcreatedflag = false;
                this.toastr.error('Failed to create VitalBridge Job', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }
            }
          }
          else if (this.interfacetype == 'Outbound' || this.interfacetype == 'EllkayOutbound') {
            if (this.outboundmodelradio["option"] === 'FTP' || this.outboundmodelradio["option"] === 'Other') {
              if (this.ftpflag && !this.ftpalreadycreated && this.outboundmodelradio["option"] != 'FTPV2') {
                this.ftpcreateddisplaylabel = true;
                this.ftpcreatedflag = true;
                this.toastr.success('FTP User created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }
              if (this.ftpflag && !this.ftpalreadycreated && this.outboundmodelradio["option"] === 'FTPV2') {
                this.ftpcreateddisplaylabel = true;
                this.ftpcreatedflag = true;
                this.toastr.success('SFTP User created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }


              if (!this.ftpflag) {
                this.ftpcreateddisplaylabel = true;
                this.ftpcreatedflag = false;
                this.toastr.error('Failed to create FTP User', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }
            }
            // this.isshownellkykeys
            if (this.popupflag && this.ProcessorType != 'L2L' && this.ProcessorType.toLowerCase() != 'splitter' && this.ProcessorType.toLowerCase() != 'batch') {
              if (this.ruleflag) {
                this.rulecreateddisplaylabel = true;
                this.rulecreatedflag = true;
                this.toastr.success('Rule created Successfully ', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }
              if (!this.ruleflag) {
                this.rulecreateddisplaylabel = true;
                this.rulecreatedflag = false;
                this.toastr.error('Failed to create Rule', "", { timeOut: 6000, positionClass: 'toast-bottom-right' });
              }
            }
          }

          //notifications end
          this.UpdateConnectivityDetails();
          this.ngxUiLoaderService.stop();
          //set copy tab title
          if (this.modelradio["option"] == 'VB Client') {
            this.copytitle = 'Copy Job Name';
          }
          else if (this.modelradio["option"] == 'FTP' || this.modelradio["option"] == 'FTP/VPN') {
            this.copytitle = 'Copy FTP Details';
          }
        }
        else {
          this.ngxUiLoaderService.stop();
          this.toastr.warning('Unable to save the pipeline', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }

    }
    catch (e) {
      this.ngxUiLoaderService.stop();
      this.toastr.warning(e.message, 'Unable to save the pipeline due to - ', {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }


  }

  async reinitiatehandler(mymodalinterfacedescription) {
    this.ruleSpinner = false;
    this.handlerSpinner = true;

    this.selectedInterfacedescription = "";
    await this.createhandlerandrule(this.deploymentsConfigurations, this.sourcedeployementkey, true);
    await this.gethandlerrulestatus();
    //this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
  }
  async reinitiateFTP() {
    if (this.modelradio["option"] == 'FTPV2') {
      this.isShownorderinboundftp = true;
      this.isShownorderinbound = true;
    }
    if (this.outboundmodelradio["option"] == 'FTPV2') {
      this.isShownresultoutbound = true;
      this.modelradio["option"] = this.outboundmodelradio["option"];
    }
    this.ngxUiLoaderService.start();
    await this.createFTP();
    await this.gethandlerrulestatus();
    this.ngxUiLoaderService.stop();
  }
  async reinitiateVBjobs() {
    this.ngxUiLoaderService.start();
    var createjobresponse = await this.createvbjob();
    await this.SaveVbJobConfig(createjobresponse);
    this.ngxUiLoaderService.stop();
  }
  async reinitiateRules(mymodalinterfacedescription) {
    this.ruleSpinner = true;
    this.handlerSpinner = false;

    this.selectedInterfacedescription = "";
    await this.createhandlerandrule(this.deploymentsConfigurations, this.sourcedeployementkey, true);
    await this.gethandlerrulestatus();
    //this.modalService.open(mymodalinterfacedescription, { windowClass: "interfacedescription", backdrop: 'static', keyboard: false }).result.then((result) => { });
  }


  createvpnconnectivity(folderpath, archivepath) {
    let vpnobj = {}
    if (this.interfacetype == 'Inbound' && this.modelradio["option"].toLowerCase() == 'vpn') {
      vpnobj['ip'] = this.modelvpn.ip
      vpnobj['port'] = this.modelvpn.port
      vpnobj['host'] = this.modelvpn.host
      vpnobj['destinationpath'] = this.sourcepath;
      vpnobj['sourcepath'] = ''
      vpnobj['archivepath'] = ''
    }
    else if (this.interfacetype == 'Outbound' && this.outboundmodelradio["option"].toLowerCase() == 'vpn') {
      vpnobj['ip'] = this.ipvpnout
      vpnobj['port'] = this.portvpnout
      vpnobj['host'] = this.hostvpnout
      vpnobj['destinationpath'] = ''
      vpnobj['sourcepath'] = folderpath
      vpnobj['archivepath'] = archivepath
    }
    return vpnobj;
  }


  async getorgid(guid, dpkey) {
    if (guid != '' && dpkey != '') {
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + guid;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        return '';
      } else {
        if (response.length > 0) {
          let res = response as any[];
          return response.find(s => s.deploymentkey == dpkey && s.LocationGUID == guid).organizationid
        }
      }
    }
  }



  async createhandlerandrule(deployementconfig: any, deployementkey: string, reprocess: boolean) {

    if (deployementconfig != undefined && this.ProcessorType != undefined && this.ProcessorType != 'L2L' && this.ProcessorType.toLowerCase() != 'batch' && this.ProcessorType.toLowerCase() != 'splitter') {

      if (this.selectedrouteDisplayName.includes("(")) {
        this.selectedrouteDisplayName = this.selectedrouteDisplayName.split("(")[0].trim()
      }
      const createhandlerjsonvalues = environment.HandlerandRuleCreate.HandlerPostJson;

      createhandlerjsonvalues.deploymentkey = deployementkey;
      createhandlerjsonvalues.handler = this.selectedrouteDisplayName;
      createhandlerjsonvalues.description = this.selectedInterfacedescription;
      createhandlerjsonvalues.groupID = this.selectedrouteId;
      if (this.interfacetype == 'EllkayOutbound' || this.interfacetype == 'Outbound') {
        createhandlerjsonvalues.direction = "Out Bound";
        if (this.selectedsourceorgid == '' || this.selectedsourceorgid == undefined) {
          await this.getorgid(this.selectedsourceaccountid, deployementkey).then((response) => {
            this.selectedsourceorgid = response;
          });
        }
        createhandlerjsonvalues.ownerLabOrgID = this.selectedsourceorgid;
        createhandlerjsonvalues.localOutboundFolder = this.sourcepath;
        createhandlerjsonvalues.localOutboundBckFolder = this.archivepath;
        createhandlerjsonvalues.localSourceFolder = '';
        createhandlerjsonvalues.localProcessingFolder = '';
        createhandlerjsonvalues.localFailedFolder = '';
        createhandlerjsonvalues.localSuccessFolder = '';
      }
      else {
        createhandlerjsonvalues.localOutboundFolder = 'NA';
        createhandlerjsonvalues.localOutboundBckFolder = 'NA';
        createhandlerjsonvalues.direction = "In Bound";
        if (this.selectedtargetorgid == '' || this.selectedtargetorgid == undefined) {
          await this.getorgid(this.selectedtargetaccountid, deployementkey).then((response) => {
            this.selectedtargetorgid = response;
          });
        }
        createhandlerjsonvalues.ownerLabOrgID = this.selectedtargetorgid;
        createhandlerjsonvalues.localSourceFolder = this.sourcepath;
        createhandlerjsonvalues.localProcessingFolder = this.progresspath;
        createhandlerjsonvalues.localFailedFolder = this.errorpath;
        createhandlerjsonvalues.localSuccessFolder = this.archivepath;
      }

      if (this.suffixfromprocessor == "*" || this.suffixfromprocessor == "") {
        createhandlerjsonvalues.fileTypes = ".xml";
      }
      else {
        createhandlerjsonvalues.fileTypes = this.suffixfromprocessor;
      }
      //message type
      createhandlerjsonvalues.message = this.messagetype
      var MessageTypeslist = deployementconfig["MessageTypes"];

      if (MessageTypeslist != undefined) {
        MessageTypeslist.forEach(element => {
          if (element.from.toLowerCase() == this.messagetype.toLowerCase()) {
            createhandlerjsonvalues.message = element.to;
          }
        });
      }
      //messagesubtype
      if (this.vendarnamevalue !== '') {
        createhandlerjsonvalues.vendorName = this.vendarnamevalue;
      }

      var MessageSubTypeslist = deployementconfig["MessageSubTypes"];
      MessageSubTypeslist.forEach(element => {
        if (element.Source.includes((this.sourceentitytype + this.sourcetype)) && element.Target.includes((this.targetentitytype + this.targettype))) {
          createhandlerjsonvalues.messageSubtype = element.Value;
        }
      });

      //active and inactive based on status of passive or active
      if (this.myFlagForSlideToggle) {
        createhandlerjsonvalues.isActive = 1;
      }
      else {
        createhandlerjsonvalues.isActive = 0;
      }
      // if (reprocess) {
      //   this.ngxUiLoaderService.start()
      // }
      createhandlerjsonvalues["contextid"] = this.UniqueID;
      createhandlerjsonvalues["username"] = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
      createhandlerjsonvalues['handlerrecreation'] = this.handlerSpinner;
      let handlerresponse = await this.service.dxpostapi("api/VitalHubDXAPI/createhandler", createhandlerjsonvalues);
      if (handlerresponse['status'] !== 'success') {
        this.handlerflag = false;
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failed to create Handler' + handlerresponse['statusText'], "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }
      else {
        if (handlerresponse['status'] == 'success') {
          this.handlerflag = true;
          let newvalue = 'Handler created'
          if (reprocess && this.handlerSpinner) {
            this.handlerSpinner = false;
            newvalue = 'Handler ReCreated'
            this.toastr.success('Handler ReCreation ' + "  " + handlerresponse['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            let obj = {
              id: this.selectedrouteId,
              displayname: this.selectedrouteDisplayName
            }
            this.selectedinterfaceEvent(obj)
          }
        }
        else {
          this.handlerflag = false;
          //audit here
          let newvalue = 'Handler not created'
          if (reprocess && this.handlerSpinner) {
            this.handlerSpinner = false;
            this.toastr.error('  Handler ReCreation ' + handlerresponse['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            newvalue = 'Handler not recreated'
          }
        }
        if (this.interfacetype == 'EllkayOutbound' || this.interfacetype == 'Outbound') {


          this.createrulejsonvalues.deploymentkey = deployementkey;
          this.createrulejsonvalues.RuleName = this.selectedrouteDisplayName;
          this.createrulejsonvalues.RuleDesc = this.selectedrouteDisplayName;
          this.createrulejsonvalues.Mode = this.eventtype ? this.eventtype : 102;

          if (this.sourcetypedisplay == 'O') {
            this.createrulejsonvalues.LabId = '-1';
            this.createrulejsonvalues.LabOrgId = this.sourceentityprimaryid;
          }
          else if (this.sourcetypedisplay == 'A') {
            this.createrulejsonvalues.LabId = this.sourceentityprimaryid;
            this.createrulejsonvalues.LabOrgId = this.selectedsourceorgid;
          }

          if (this.targettypedisplay == 'O') {
            this.createrulejsonvalues.OrdFacilityId = '-1';
            this.createrulejsonvalues.OrdFacOrgId = this.targetentityprimaryid;
          }
          else if (this.targettypedisplay == 'A') {
            this.createrulejsonvalues.OrdFacilityId = this.targetentityprimaryid;
            this.createrulejsonvalues.OrdFacOrgId = this.selectedtargetorgid;
          }

          //active and inactive based on status of passive or active
          if (this.myFlagForSlideToggle) {
            this.createrulejsonvalues.Status = 1;
          }
          else {
            this.createrulejsonvalues.Status = 2;
          }
          this.createrulejsonvalues["contextid"] = this.UniqueID;
          this.createrulejsonvalues["username"] = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
          this.createrulejsonvalues['rulerecreation'] = this.ruleSpinner
          var rulesresponse = await this.service.postapi("api/VitalHubDXAPI/CreateVaRule", this.createrulejsonvalues);
          if (rulesresponse['status'] !== 'success') {
            this.ruleflag = false;
            this.rulebuttonshow = false;
            return false;
          }
          if (rulesresponse['status'] == 'success') {
            this.ruleflag = true;
            this.rulebuttonshow = true;
            let newvalue = 'Rule created'
            if (reprocess && this.ruleSpinner) {
              this.ruleSpinner = false;
              this.toastr.success('Rule ReCreation ' + "  " + rulesresponse['statusmessage'], "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
              let obj = {
                id: this.selectedrouteId,
                displayname: this.selectedrouteDisplayName
              }
              newvalue = 'Rule ReCreated'
              this.selectedinterfaceEvent(obj)
            }
            //audit here
          }
          else {
            this.ruleflag = false;
            //audit here
            let newvalue = 'Rule not created'
            if (reprocess && this.ruleSpinner) {
              this.ruleSpinner = false;
              this.toastr.error('Rule ReCreation ' + "  " + rulesresponse['statusmessage'], "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
              newvalue = 'Rule not recreated'
            }
          }

          const createinterfacejsonvalues = environment.HandlerandRuleCreate.InterfacePostJson;
          createinterfacejsonvalues.deploymentkey = deployementkey;
          createinterfacejsonvalues.Handler = this.selectedrouteDisplayName;
          createinterfacejsonvalues.InterFaceName = this.selectedrouteDisplayName;
          createinterfacejsonvalues.Description = this.selectedrouteDisplayName;

          let interfaceresponse = await this.service.dxpostapi("api/VitalHubDXAPI/CreateInterfaceDetails", createinterfacejsonvalues);

          if (interfaceresponse['status'] !== 'success') {
            this.interfaceflag = false;
            return false;
          }
          if (interfaceresponse['status'] == 'success') {
            this.interfaceflag = true;

            //audit here
            let auditresponse = await this.service.auditsave("Add", "", "Interface Details created ", "Pipeline", this.UniqueID, "Interface Details created Sucessfully")
            if (auditresponse['status'] && auditresponse['statusText']) {
              this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            }
          }
          else {
            this.interfaceflag = false;
            //audit here
            let auditresponse = await this.service.auditsave("Add", "", "Interface Details not created ", "Pipeline", this.UniqueID, "Interface Details not created ")
            if (auditresponse['status'] && auditresponse['statusText']) {
              this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            }
          }

          return true;
        }
        else {
          this.interfaceflag = true;
          return true;
        }
      }
    }

  }

  async updatehandlerandrule(deployementconfig: any, deployementkey: string) {
    if (deployementconfig != undefined) {
      const createhandlerjsonvalues = environment.HandlerandRuleCreate.HandlerPostJson;
      createhandlerjsonvalues.handler = this.selectedrouteDisplayName.trimLeft().trimRight();
      createhandlerjsonvalues.description = this.selectedInterfacedescription;
      createhandlerjsonvalues.groupID = this.selectedrouteId;
      createhandlerjsonvalues.deploymentkey = deployementkey;
      if (this.interfacetype == 'EllkayOutbound' || this.interfacetype == 'Outbound') {
        createhandlerjsonvalues.direction = "Out Bound";
        createhandlerjsonvalues.ownerLabOrgID = this.selectedsourceorgid;
        createhandlerjsonvalues.localOutboundFolder = this.sourcepath;
        createhandlerjsonvalues.localOutboundBckFolder = this.archivepath;
        createhandlerjsonvalues.localSourceFolder = '';
        createhandlerjsonvalues.localProcessingFolder = '';
        createhandlerjsonvalues.localFailedFolder = '';
        createhandlerjsonvalues.localSuccessFolder = '';
      }
      else {
        createhandlerjsonvalues.localOutboundFolder = 'NA';
        createhandlerjsonvalues.localOutboundBckFolder = 'NA';
        createhandlerjsonvalues.direction = "In Bound";
        createhandlerjsonvalues.ownerLabOrgID = this.selectedtargetorgid;
        createhandlerjsonvalues.localSourceFolder = this.sourcepath;
        createhandlerjsonvalues.localProcessingFolder = this.progresspath;
        createhandlerjsonvalues.localFailedFolder = this.errorpath;
        createhandlerjsonvalues.localSuccessFolder = this.archivepath;
      }

      if (this.suffixfromprocessor == "*" || this.suffixfromprocessor == "") {
        createhandlerjsonvalues.fileTypes = ".xml";
      }
      else {
        createhandlerjsonvalues.fileTypes = this.suffixfromprocessor;
      }
      //message type
      createhandlerjsonvalues.message = this.messagetype
      var MessageTypeslist = deployementconfig["MessageTypes"];

      if (MessageTypeslist != undefined) {
        MessageTypeslist.forEach(element => {
          if (element.from.toLowerCase() == this.messagetype.toLowerCase()) {
            createhandlerjsonvalues.message = element.to;
          }
        });
      }
      //messagesubtype
      if (this.vendarnamevalue !== '') {
        createhandlerjsonvalues.vendorName = this.vendarnamevalue;
      }

      var MessageSubTypeslist = deployementconfig["MessageSubTypes"];
      MessageSubTypeslist.forEach(element => {
        if (element.Source.includes((this.sourceentitytype + this.sourcetype)) && element.Target.includes((this.targetentitytype + this.targettype))) {
          createhandlerjsonvalues.messageSubtype = element.Value;
        }
      });

      //active and inactive based on status of passive or active
      if (this.myFlagForSlideToggle) {
        createhandlerjsonvalues.isActive = 1;
      }
      else {
        createhandlerjsonvalues.isActive = 0;
      }
      createhandlerjsonvalues.isActive = 0;
      var handlerresponse = await this.service.dxpostapi('api/VitalHubDXAPI/UpdateHandlerRulename', createhandlerjsonvalues);
      if (handlerresponse['status'] && handlerresponse['statusText']) {
        this.handlerflag = false;
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failed to update Handler name and Rule name ' + handlerresponse['statusText'], "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }
      else {

        if (handlerresponse['status'] == 'Success') {
          this.handlerflag = true;
          //audit here
          var responseaudit = await this.service.auditsave("Add", "", "Handler updates", "Pipeline", this.UniqueID, 'Handler updated successfully')
          if (responseaudit['status'] && responseaudit['statusText']) {
            this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }

          var responseaudit = await this.service.auditsave("Add", "", "Rule updates ", "Pipeline", this.UniqueID, 'Varule updated successfully')
          if (responseaudit['status'] && responseaudit['statusText']) {
            this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }

        }
        else {
          this.handlerflag = false;
          //audit here
          var responseaudit = await this.service.auditsave("Add", "", "Handler not updated ", "Pipeline", this.UniqueID, 'Handler not updated "')
          if (responseaudit['status'] && responseaudit['statusText']) {
            this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }

          var responseaudit = await this.service.auditsave("Add", "", "Rule not updated ", "Pipeline", this.UniqueID, 'Rule not updated "')
          if (responseaudit['status'] && responseaudit['statusText']) {
            this.toastr.error("Unable to Audit the transaction", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          return true;
        }
      }
    }
  }
  //get the deployement configuration in the agent level
  async getdeploymentconfiguration() {
    var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
    var responseconfig = await this.service.getapi(apiname);
    var configs = JSON.parse(responseconfig.responsebody);
    return configs;
  }

  //existing FTP create VB
  modelvbftp: any = {};
  vbjobdisplayexftp: boolean = false;
  ftpisDisabled: boolean = false;
  vbjobnameftp: string = '';

  async createvbjobexistingftp() {
    if (this.selectedjobName == '' && this.selectedjobId == '') {
      this.sourcepath = this.modelvbftp.sourcepath;
      this.archivepath = this.modelvbftp.archivepath;
      this.errorpath = this.modelvbftp.errorpath;
      this.progresspath = this.modelvbftp.progresspath;
      var createjobresponse = await this.createvbjob();
      if (createjobresponse.status == "success") {
        this.selectedjobId = createjobresponse.jobid;
        this.selectedjobName = createjobresponse.jobname;
        this.ftpflag = false;
        this.vbflag = true;
        this.vbjobnameftp = this.selectedjobName;
        this.vbjobdisplayexftp = true;
        if (this.modelradio["option"] == 'Other') {
          this.ftpisDisabled = true;
        }
        else {
          this.ftpisDisabled = false;
        }
      }

    }


  }


  copytitle: string = '';
  async checkvbagentexist() {

    if (Object.keys(this.modelradio).length != 0) {
      if (this.modelradio["option"] == "VB Client") {
        if (Object.keys(this.modelvbclient).length != 0) {
          if (this.modelvbclient.vbclientagnetid == '' || this.modelvbclient.vbclientagnetid == undefined || this.modelvbclient.vbclientagnetid == 'select') {

            this.toastr.warning('Agent Id is mandatory', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            return false;
          }
          else if (this.modelvbclient.vbclientpath == '' || this.modelvbclient.vbclientpath == undefined) {

            this.toastr.warning("Folder path is mandatory", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            return false;
          }
          else {
            return true;
          }
        }
        else {

          this.toastr.warning("Please enter the required fields", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return false;
        }
      }
      else {
        return true;
      }
    }
    else {
      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
  }
  remotepathdisplay: string = '';
  sourcepath: string = '';
  archivepath: string = '';
  errorpath: string = '';
  progresspath: string = '';
  ftpusername: string;
  ftppassword: string;
  ftphost: string;
  ftpport: string;
  vbjobname: string;
  vbtype: string;

  async getftppaths(path: any) {
    path = path.replace(/\//g, "");

    if (this.sourcepath == '' || this.sourcepath == undefined) {
      this.sourcepath = path;
    }

    if (this.archivepath == '' || this.archivepath == undefined) {
      this.archivepath = path + '\archive';
      if (this.modelradio["option"] === "FTPV2" || this.outboundmodelradio["option"] === 'FTPV2') {
        this.archivepath = path + '\\archive';
      }
    }

    if (this.errorpath == '' || this.errorpath == undefined) {
      this.errorpath = path + '\error';
      if (this.modelradio["option"] === "FTPV2" || this.outboundmodelradio["option"] === 'FTPV2') {
        this.errorpath = path + '\\error';
      }
    }

    if (this.progresspath == '' || this.progresspath == undefined) {
      this.progresspath = path + '\progress';
      if (this.modelradio["option"] == "FTPV2" || this.outboundmodelradio["option"] === 'FTPV2') {
        this.progresspath = path + '\\progress';
      }
    }

  }

  async getrandamusernamepassword() {

    if (this.ftpusername == '' || this.ftpusername == undefined) {
      this.ftpusername = this.sourceentitymnemonic + '-' + this.targetentitymnemonic + '-' + this.vendormnemonic + '-' + Math.floor(Math.random() * 1000 + 1000);
    }

    if (this.ftppassword == '' || this.ftppassword == undefined) {

      var lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      var upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      var symbols = ['!', '?', '@'];

      var getRandom = function (array) {
        return array[Math.floor(Math.random() * array.length)];
      }

      var finalCharacters = "";
      finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
      finalCharacters = finalCharacters.concat(getRandom(numbers));
      finalCharacters = finalCharacters.concat(getRandom(symbols));

      for (var i = 0; i < 10 - 3; i++) {
        finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
      }

      finalCharacters.split('').sort(function () { return 0.5 - Math.random() }).join('');
      this.ftppassword = finalCharacters;
    }

  }
  vbreturndetails: any = {
    "status": "",
    "jobid": '',
    "jobname": ""
  };
  extensions: Array<string> = [];
  vbFileWatchUploadJob: any = {};
  async createvbjob() {
    //call the VB api to create the job
    this.vbreturndetails = {
      "status": "",
      "jobid": '',
      "jobname": ""
    };

    var extensionsmulti = '';
    this.selectedextensionItems.forEach(element => {
      extensionsmulti = element.item_text;
      this.extensions.push(extensionsmulti)
    });
    if (this.selectedextensionItems == '') {

    }
    else {
      this.vbFileWatchUploadJob['FileExtensionsList'] = this.extensions;
    }
    this.vbFileWatchUploadJob['JobName'] = this.selectedrouteDisplayName; //job name and routename is same
    this.vbFileWatchUploadJob['FileWatchPath'] = this.sourcepath;
    this.vbFileWatchUploadJob['FileWatchArchivePath'] = this.archivepath;
    this.vbFileWatchUploadJob['FileWatchErrorFolder'] = this.errorpath;
    this.vbFileWatchUploadJob['FileWatchProgressPath'] = this.progresspath;
    this.vbFileWatchUploadJob['FileWatchRemotePath'] = this.suggestedroutename

    this.vbFileWatchUploadJob['FileWatchMessageType'] = this.messagetype;
    this.vbFileWatchUploadJob['SourceEntityId'] = this.selectedsourceaccountid;
    this.vbFileWatchUploadJob['DestinationEntityId'] = this.selectedtargetaccountid;
    this.vbFileWatchUploadJob['RouteDefinitionId'] = this.selectedrouteId;
    this.vbFileWatchUploadJob['AgentId'] = this.envSourceProxy;
    this.vbFileWatchUploadJob['Status'] = this.myFlagForSlideToggle ? "Start" : "Stop";
    this.vbFileWatchUploadJob['CreatedBy'] = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
    this.vbFileWatchUploadJob['JobType'] = "FileWatchAndUpload";
    this.vbFileWatchUploadJob['Connectivitytype'] = this.modelradio["option"];
    this.vbFileWatchUploadJob['UniqueID'] = this.UniqueID;
    this.vbFileWatchUploadJob['OrgID'] = this.selectedsourceaccountid;

    let response = await this.service.postapi('api/VBJobs/SaveVBFileJob', this.vbFileWatchUploadJob);
    if (response.status == 'success') {
      this.vbreturndetails.status = 'success';
      this.vbreturndetails.jobid = response.content["jobid"];
      this.vbreturndetails.jobname = response.content["jobname"];
    }


    return this.vbreturndetails;
  }
  async validatedata() {
    let flag = true; let pflag = true;
    if (this.ProcessorType == 'L2L' && Number(this.UniqueID) > 0) {
      pflag = false;
    }

    if (pflag) {
      //processor checks
      this.dynamicJSON.forEach(array => {
        if (array.formgroupname.controls[array.formcontrolname].value === '') {
          flag = false;
        }
      });
    }
    let list = [];
    if (flag) {
      this.dynamicJSON.forEach(element => {
        if (element.mapicon) {
          if (element.formgroupname.controls[element.formcontrolname].value.UniqueID == undefined) {
            list.push(this.ProcessorAllList.find(x => x.id == element.formgroupname.controls[element.formcontrolname].value.split('(')[1].split(')')[0]).UniqueID);
          }
          else {
            list.push(element.formgroupname.controls[element.formcontrolname].value.UniqueID);
          }
        }
        else {
          list.push(element.formgroupname.controls[element.formcontrolname].value.UniqueID);
        }
      });

      var filterids = list.filter((ele, indx) => indx !== list.indexOf(ele));
      if (filterids.length > 0) {
        flag = false;
      }
      else {
        flag = true;
      }
    }

    if (flag) {
      this.selectedprocessagentid = this.selectedprocessagentid == undefined ? this.reactiveFormprocess.value.namep.split('(')[1].split(')')[0] : this.selectedprocessagentid;
      this.selectedsourceaccountid = this.selectedsourceaccountid == undefined ? this.reactiveFormsource.value.name.LocationGUID : this.selectedsourceaccountid;
      this.selectedtargetaccountid = this.selectedtargetaccountid == undefined ? this.reactiveFormtarget.value.namet.LocationGUID : this.selectedtargetaccountid;

      if (this.selectedprocessagentid == '' && this.reactiveFormprocess.get('namep').value) {
        this.selectedprocessagentid = this.reactiveFormprocess.get('namep').value.id;
      }

      if (this.selectedsourceaccountid == '' && this.reactiveFormsource.get('name').value) {
        this.selectedsourceaccountid = this.reactiveFormsource.get('name').value.id;
      }

      if (this.selectedtargetaccountid == '' && this.reactiveFormtarget.get('namet').value) {
        this.selectedtargetaccountid = this.reactiveFormtarget.get('namet').value.id;
      }

      if (this.selectedprocessagentid == "") {
        this.toastr.warning('Processor is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

      if (this.selectedsourceaccountid == "" || this.selectedsourceaccountid == undefined) {
        this.toastr.warning('Source Entity is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

      if (this.selectedtargetaccountid == "" || this.selectedtargetaccountid == undefined) {
        this.toastr.warning('Target Entity is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

      if (this.modeSelected == "") {
        this.toastr.warning('Mode is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }


      if (!this.reactiveFormvendar.get('namevendar').value || !this.vendarnamevalue) {
        this.toastr.warning('Vendor name is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }


      if (this.isshownellkykeys) {
        if (!this.modelelk.subscriberkey
          || !this.modelelk.servicekey) {
          this.toastr.warning('Keys are mandatory', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return false;
        }
      }

      if (this.selectedrouteDisplayName == "" && (this.reactiveFormroute.value.routename == null || this.reactiveFormroute.value.routename == '')) {
        this.toastr.warning('Pipeline is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

      if (this.ftpcheckexsting) {
        if (this.ftpexistingpath == '') {
          this.toastr.warning('FTP path is mandatory', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return false;
        }
      }


      if (this.interfacetype == "Inbound" && this.modelradio["option"] == undefined) {
        this.toastr.warning('Connectivity is mandatory', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

      if (this.interfacetype !== "EllkayInbound" && this.interfacetype !== "EllkayOutbound") {

        if (this.modelradio["option"] == "VPN" && (!this.modelvpn.ip || !this.modelvpn.port || !this.modelvpn.host)) {
          this.toastr.warning('VPN details are mandatory', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return false;
        }

        if (this.modelradio["option"] == "External FTP" && (!this.customftp.username || !this.customftp.password || !this.customftp.host || !this.customftp.port || !this.customftp.folder || !this.customftp.archivepath)) {
          this.toastr.warning('External Ftp details are mandatory', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return false;
        }

      }

      if (this.interfacetype == 'Inbound') {
        if (!this.selectedjobName && !this.selectedjobId) {
          return await this.checkvbagentexist();
        }
      }
      else {
        return true;
      }
      return true;
    }
    else {
      this.toastr.warning('Processor is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
  }


  Templatearray: any[];
  closeResult: string;
  processorurl: string = '';
  open(rowdata) {
    if (rowdata !== '') {
      var controldata = this.dynamicJSON.filter(a => a.sequence == rowdata.sequence);
      var filterdata;
      if (controldata[0].formgroupname.value[controldata[0].formcontrolname].displayname == undefined) {
        filterdata = this.ProcessorAllList.find(d => d.displayname == controldata[0].formgroupname.value[controldata[0].formcontrolname])
      }
      else {
        filterdata = this.ProcessorAllList.find(d => d.displayname == controldata[0].formgroupname.value[controldata[0].formcontrolname].displayname);
      }
      if ((filterdata.processorurl != '' || filterdata.processor) &&
        filterdata.processortype == 'General') {
        if (this.selectedrouteId) {
          this.ngxUiLoaderService.start();
          var url = "api/GetMappings/Get?routeid=" + this.selectedrouteId + "&processorid=" + filterdata.id + "&processorapikey=" + filterdata.apikey;

          this.service.getapiurl(url).subscribe({
            next: responce => {
              this.Templatearray = responce as any[];
              var interfacetype = this.Templatearray["routeDetails"].INTERFACETYPE;


              if (interfacetype && interfacetype.trim() === "Inbound") {
                this.disableGeneratePDFButton = true;
              } else {
                this.disableGeneratePDFButton = false;
              }
              this.dataToModal = {
                lockXMLmap: this.isLocked,
                MappedXml: this.Templatearray["mappedxml"],
                routeid: this.selectedrouteId,
                processorid: filterdata.id,
                templateid: this.Templatearray["template"],
                templatelist: this.Templatearray["templatelist"],
                resultJson: this.Templatearray["resultjson"],
                routeName: this.selectedrouteDisplayName,
                ProcessorName: filterdata.name,
                processorapikey: filterdata.apikey,
                uniqueid: this.UniqueID,
                routedetails: this.Templatearray["routeDetails"],
                CoreConfigVersionList: this.Templatearray["CoreConfigVersionList"],
                interfaceType: interfacetype,
                disablePDFButton: this.disableGeneratePDFButton
              };
              this.ngxUiLoaderService.stop();
              //check vendor has morethan one template

              if (this.popupflag && responce["templatelist"].filter(x => x.vendor == this.vendorid && x.direction.toLowerCase() == (this.interfacetype ? this.interfacetype.toLowerCase() : '') && x.messagetype == (this.messagetype ? this.messagetype.toLowerCase() : '')).length > 1) {
                const message = this.vendarnamevalue.toUpperCase() + ` Vendor has more than one template !!`;

                const dialogData = new ConfirmDialogModel("Select Template", message, 'select', responce["templatelist"].filter(x => x.vendor == this.vendorid && x.direction.toLowerCase() == (this.interfacetype ? this.interfacetype.toLowerCase() : '') && x.messagetype == (this.messagetype ? this.messagetype.toLowerCase() : '')));
                const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: "700px", data: dialogData, disableClose: true });

                dialogRef.afterClosed().subscribe(async dialogResult => {
                  if (dialogResult === false) {
                    this.ngxUiLoaderService.stop();
                    return;
                  }
                  else {

                    this.dataToModal.templateid = dialogResult;
                    this.dataToModal.MappedXml = this.Templatearray["templatelist"].find(x => x.id == dialogResult)["mappedxml"];
                    const modalRef = this.modalService.open(XmlmappingsComponent,
                      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
                    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
                    modalRef.result.then((response) => {
                      this.ngxUiLoaderService.start();
                      this.getRouteDetails().then((response) => {
                        this.ngxUiLoaderService.stop();
                      });
                    });
                  }
                });
              }
              else {
                if (responce["templatelist"].filter(x => x.vendor == this.vendarnamevalue && x.direction == this.interfacetype && x.messagetype == this.messagetype) > 0) {
                  if (responce["templatelist"].filter(x => x.vendor == this.vendarnamevalue && x.direction == this.interfacetype && x.messagetype == this.messagetype)[0].id !== '') {
                    this.dataToModal.templateid = responce["templatelist"].filter(x => x.vendor == this.vendarnamevalue && x.direction == this.interfacetype && x.messagetype == this.messagetype)[0].id;
                  }
                }

                if (this.Templatearray["mappedxml"] != null) {
                  const modalRef = this.modalService.open(XmlmappingsComponent,
                    { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
                  modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
                  modalRef.result.then((response) => {
                    this.ngxUiLoaderService.start();
                    this.getRouteDetails().then((response) => {
                      this.ngxUiLoaderService.stop();
                    });
                  });
                }
                else {
                  this.modalService.dismissAll();
                  this.toastr.warning('Template configurations are not available', "", {
                    timeOut: 4000,
                    positionClass: 'toast-bottom-right'
                  });
                }
              }
            },
            error: error => {
              this.ngxUiLoaderService.stop();

              this.toastr.error('Unable to fetch Mapping Configuration', "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
            }
          });
        }
        else {
          this.toastr.warning('Please select the Pipeline Name', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
      else {
        this.toastr.warning('Mappings are not applicable for this ' + filterdata.processortype + ' processor type', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.getRouteDetails();
      }
    }
    else {
      this.toastr.warning('Pipeline and Processor are mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  //Tryitout modal start
  public dataToModal: any;
  async opentryiytout() {
    let fileUploadFTP: boolean;
    let enableAccessionSearch: boolean;
    if (this.selectedrouteId != '' && this.selectedprocessagentid != '') {

      this.ngxUiLoaderService.start();
      var response = await this.getFileConfig(this.selectedrouteId, this.selectedprocessagentid);
      if (response != "") {
        var jsonData = JSON.parse(response[0]["json"]);
        var vaconfigdata = jsonData.processorconfig["vaconfig"];
        var interfacetype = jsonData.processorconfig["interfacetype"];

        if (this.messagetype === "order") {
          fileUploadFTP = true;
          enableAccessionSearch = false;
        } else if (this.messagetype === "result") {
          fileUploadFTP = false;
          enableAccessionSearch = true;
        }
        else {
          fileUploadFTP = false;
          enableAccessionSearch = false;
        }
      }

      this.dataToModal = {
        RouteIdtry: this.selectedrouteId,
        ProcessorIdtry: this.selectedprocessagentid,
        ProcessorDisplayNametry: this.selectedprocessdisplayname,
        RouteDisplayNametry: this.selectedrouteDisplayName,
        Processorapikey: this.selectedprocessapikey,
        Popupflag: true,
        MappedXmlTemp: '',
        isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
        isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
        interfacetype: interfacetype,
        enableAccessionSearch: enableAccessionSearch,
        fileUploadFTP: fileUploadFTP,
        sourcedeploymentkey: this.sourcedeployementkey,
        targetdeploymentkey: this.targetdeployementkey,
        sourceaccounttype: this.sourcetype,
        targetaccounttype: this.targettype,
        seguid: this.selectedsourceaccountid,
        deguid: this.selectedtargetaccountid,
        sourceagent: this.envSourceProxy,
        Uniqueid: this.UniqueID
      };

      const modalRef = this.modalService.open(TryitoutComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });

      modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
      modalRef.result.then(() => { });
      this.ngxUiLoaderService.stop();
    }
    else {

      this.toastr.warning('Pipeline and processor are mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

  }
  //Tryitout modal end

  messagetype: string = '';


  isLoadingSource: boolean = false;
  isLoadingTarget: boolean = false;
  isLoadingProcesor: boolean = false;

  searchSourceNameCleared(event) {
    this.reqfieldslist = []
    this.DeviceList = []
    this.vbagentlist = []
    this.selecteddevice = ''
    this.selectedvbagentname = '';
    this.selectedvbagentid = '';
    this.connectivitytypemsg = "Select the source entity";
    this.isLoadingSource = false;
    this.SourceAccountList = [];
    this.reactiveFormprojectname.setValue({ "projectname": '' });
    this.projectnamelist = [];
    this.selectedsourceaccountName = '';
    this.selectedsourceaccountid = '';
    this.sourcetypedisplay = '';
    this.sourcetypedisplayid = '';
    this.sourcedpkeydisplay = '';
    this.RouteNameSuggest[1]["value"] = '';
    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });
    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
    this.sourceentityaddress = '';
    this.sourceentityaddress1 = '';
    this.sourceentityaddress2 = '';
    this.sourcecity = '';
    this.sourcestate = '';
    this.sourcezip = '';
    this.notfounddata = false;
    this.sourcedeployementkey = '';
    this.sourceassociatedaccountids = '';
  }
  searchTargetNameCleared(event) {
    this.reqfieldslist = []
    this.DeviceList = []
    this.vbagentlist = []
    this.selecteddevice = ''
    this.selectedvbagentname = '';
    this.selectedvbagentid = '';
    this.reactiveFormprojectname.setValue({ "projectname": '' });
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.projectnamelist = [];
    this.selectedtargetaccountName = '';
    this.selectedtargetaccountid = '';
    this.targettypedisplay = '';
    this.targettypedisplayid = '';
    this.targetdpkeydisplay = '';
    this.RouteNameSuggest[2]["value"] = '';
    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });
    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
    this.targetentityaddress = '';
    this.targetentityaddress1 = '';
    this.targetentityaddress2 = '';
    this.targetcity = '';
    this.targetstate = '';
    this.targetzip = '';
    this.notfounddata = false;
    this.targetassociatedaccountids = '';
    this.targetdeployementkey = '';
  }

  ftpcheckexsting: boolean = false;
  resultsftpchecknone: boolean = false;
  apiconnectivity: boolean = false;

  ftpexistingpath: string = '';

  async searchProcesorNameCleared(arraylist, datarow) {

    if (datarow !== null && datarow.formcontrolname === 'prop') {
      this.projectnamelist = [];
      this.reactiveFormprojectname.setValue({ "projectname": '' });
      this.interfacetype = '';
      this.isshownellkykeys = false;
      this.isLoadingProcesor = false;
      this.selectedprocessdisplayname = '';
      this.selectedprocessagentid = '';
      if (Object.keys(this.modelradio).length != 0) {
        this.modelradio["option"] = '';
      }
      this.VBClientmatcard = false;
      this.RouteNameSuggest[4]["value"] = '';
      this.RouteNameSuggest[5]["value"] = '';
      let myArray = [];
      this.RouteNameSuggest.forEach(element => {
        if (element.value != '') {
          myArray.push(element.value);
        }
      });
      this.reactiveFormroute.patchValue({
        routename: myArray.map(element => element).join(".")
      });
      this.suggestedroutename = this.reactiveFormroute.value.routename;
      this.vendarmsgdiv = false;
      this.ftpcheckexsting = false;
      this.ftpexistingpath = '';
      this.ftpexstingpathdiv = false;
      this.notfounddata = false;
      this.ProcessorList = this.ProcessorAllList;
      await this.searchRouteNameCleared('');
      await this.Resetalldata();
      // await this.setfirstcontrol();
    }
  }

  //vb create job integration

  vbjobList = <any>[];
  keywordjobname = 'JobName';
  isLoadingjob: boolean = false;
  selectedjobId: string = '';
  selectedjobName: string = '';


  show: boolean = false;
  togglePasswordFieldType() {
    this.show = !this.show;
  }


  myFlagForSlideToggle: boolean = false;


  copyftp() {

    if (this.modelradio["option"] == 'VB Client') {

      const ftp = 'JobName:' + this.selectedjobName + '\r\n' + 'Path:' + this.vbfolderpathdisplay;

      copyToClipboardtable(ftp)

      this.toastr.success('VB Client Details Copied ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });

    }
    else if (this.modelradio["option"] == 'FTP' || this.modelradio["option"] == 'FTP/VPN' || this.modelradio["option"] === 'External FTP' || this.modelradio["option"] === 'FTPV2') {
      let ftp = ''
      if (this.isviewpasswordftp) {
        ftp = 'UserName:' + this.ftpusername + '\r\n' + 'Password:' + this.ftppassword + '\r\n' + 'Server :' + this.ftphost + '\r\n' +
          'Port :' + this.ftpport + '\r\n' + 'Remote site :' + this.remotepathdisplay;
      } else {
        ftp = 'UserName:' + this.ftpusername + '\r\n' + 'Server :' + this.ftphost + '\r\n' +
          'Port :' + this.ftpport + '\r\n' + 'Remote site :' + this.remotepathdisplay;
      }


      copyToClipboardtable(ftp)
      if (this.modelradio["option"] == 'FTPV2') {
        this.toastr.success('SFTP Details Copied ', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.toastr.success('FTP Details Copied ', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }

    }
    else if (this.modelradio["option"] == 'Device' && this.savedjobdetails) {
      const ftp = 'DeviceName:' + this.savedjobdetails['DeviceName'] + '\r\n' + 'DeviceType:' + this.savedjobdetails['DeviceType'] + '\r\n' + 'RemoteIP :' + this.savedjobdetails.ConnectivityInfo.RemoteIP + '\r\n' +
        '\r\n' + 'RemotePort :' + this.savedjobdetails.ConnectivityInfo.RemotePort + '\r\n' + 'JobName :' + this.savedjobdetails['JobName'] + '\r\n' + 'Job Status :' + this.VBJobCurrentStatus;

      copyToClipboardtable(ftp)
      this.toastr.success('Device Integration Job Details Copied ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  copyftpoutbount() {
    if (this.outboundmodelradio["option"] == 'FTP' || this.outboundmodelradio["option"] == 'FTP/VPN' || this.outboundmodelradio["option"] === 'External FTP' || this.outboundmodelradio["option"] === 'Other' || this.outboundmodelradio["option"] === 'FTPV2') {
      let ftp = ''
      if (this.isviewpasswordftp) {
        ftp = 'UserName:' + this.ftpusername + '\r\n' + 'Password:' + this.ftppassword + '\r\n' + 'Server :' + this.ftphost + '\r\n' +
          'Port :' + this.ftpport + '\r\n' + 'Remote site :' + this.remotepathdisplay;
      } else {
        ftp = 'UserName:' + this.ftpusername + '\r\n' + 'Server :' + this.ftphost + '\r\n' +
          'Port :' + this.ftpport + '\r\n' + 'Remote site :' + this.remotepathdisplay;
      }

      copyToClipboardtable(ftp)
      if (this.outboundmodelradio["option"] == 'FTPV2') {
        this.toastr.success('SFTP Details Copied ', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.toastr.success('FTP Details Copied ', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }

    }
  }
  copyvpn(direction: string) {
    if (direction == 'In') {
      const vpn = 'IP:' + this.modelvpn.ip + '\r\n' + 'Port:' + this.modelvpn.port + '\r\n' + 'Host :' + this.modelvpn.host + '\r\n';

      copyToClipboardtable(vpn)
      this.toastr.success('VPN Details Copied ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      const vpn = 'IP:' + this.ipvpnout + '\r\n' + 'Port:' + this.portvpnout + '\r\n' + 'Host :' + this.hostvpnout + '\r\n' +
        'Source Path :' + this.sourcepathvpnout + '\r\n' + 'Destination Path:' + this.archivepathvpnout;

      copyToClipboardtable(vpn)
      this.toastr.success('VPN Details Copied ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  copyagent() {
    const agent = 'Source agent:' + this.selectedsourceagentdisplayname + '\r\n' + 'Target agent:' + this.selectedtargetagentdisplayname + '\r\n' + 'Messagetype :' + this.selectedmessagetype + '\r\n' +
      'Route policy :' + this.routepolicyselected;

    copyToClipboardtable(agent)
    this.toastr.success('Agent Details Copied ', "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  }

  isDisabled: boolean = true;
  myFlagftpchecks: boolean = true;
  masterToggle() {
    if (this.myFlagftpchecks) {
      this.isDisabled = false;
      this.myFlagftpchecks = false;
    }
    else {
      this.isDisabled = true;
      this.myFlagftpchecks = true;
    }
  }
  connectivitytypemsg: string = '';
  VBClientmatcard: boolean = false;
  rbtn: string = ''
  async selecteedvbtype(rdbvalue) {
    this.rbtn = rdbvalue
    if (rdbvalue == 'Device' && (this.selecteddevice != '' || this.selecteddevice != undefined)) {
      this.getDevicelistbyentity();
    }
    if (rdbvalue == 'Device' && (this.selectedtargetaccountid != '' && this.selectedtargetaccountid != undefined)) {
      let returndvalue = await this.Getvbagentsdetails();
    }

    if (rdbvalue != 'FTP' || rdbvalue != 'External FTP' || rdbvalue != 'External FTPV2' || rdbvalue != 'FTPV2') {
      this.isftp = false
    }
    this.modelradio = { option: rdbvalue };

    if (this.selectedsourceaccountid == '') {
      this.array_disableradiobuttons.push("disable");
      this.connectivitytypemsg = "Select the source entity";
      this.isdevicename();
    }
    else {
      this.array_disableradiobuttons = [];
      if (rdbvalue == 'Project') {
        this.getprojectname();
      }
      if (rdbvalue == 'VB Client') {
        this.ngxUiLoaderService.start();
        this.VBClientmatcard = true;
        this.modelvbclient.vbclientagnetid = 'select';
        this.modelvbclient.vbclientpath = '';
        await this.getvbagentslist();
        this.ngxUiLoaderService.stop();
      }
      else {
        this.VBClientmatcard = false;
      }
      this.connectivitytypemsg = '';
    }
  }

  async selectedoutboundtype(rdbvalue) {
    this.outboundmodelradio = { option: rdbvalue };
    this.ftpexistingpath = '';
    this.ipvpnout = '';
    this.portvpnout = '';
    this.hostvpnout = '';
    this.customftp = <CustomFTPDetails>{
      username: '',
      password: '',
      host: '',
      port: '',
      folder: '',
      id: '',
      archivepath: ''
    }
    if (this.selectedsourceaccountid && this.vendorid && this.outboundmodelradio['option'] == 'Project') {
      this.getprojectname();
    }
  }

  isftp: boolean = false
  selectftp(value: boolean) {
    this.isftp = value;
    // this.array_disableradiobuttons.push("disable");
  }

  async getJobName(jobname) {

    const PostFormData = {
      VBAgentID: this.envSourceProxy,
      JobName: jobname,
      SourceEntity: this.selectedsourceaccountid,
      DestinationEntity: this.selectedtargetaccountid,
      MessageType: this.messagetype,
      SourcePath: ''
    }
    return await this.service.postapi("api/VBJobs/GetVbFileJob", PostFormData);
  }


  vbagentsdropdown: any = [];
  async getvbagentslist() {
    if (this.selectedsourceaccountid == '') {
      this.toastr.warning('Please select the source entity and search vb agents ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      var apiname = 'api/VBJobs/GetVbAgnetsList?sourceentity=' + this.selectedsourceaccountid;
      var vbagnets = await this.service.getapi(apiname);
      if (vbagnets['status'] && vbagnets['statustext']) {
        this.toastr.error('Failed in the get vb agents', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      } else {
        if (vbagnets.status == "Success") {
          const results = JSON.parse(vbagnets.responsebody);
          results.forEach(element => {
            this.vbagentsdropdown.push({ displayname: element.DisplayName, VBAgentID: element.VBAgentID })
          });
          this.vbagentsdropdown.map(obj => { obj.displayname = `${obj.displayname} ${'(' + obj.VBAgentID + ')'}` });
          if (this.vbagentsdropdown.length == 1) {
            this.modelvbclient.vbclientagnetid = this.vbagentsdropdown[0].VBAgentID;
            this.reactiveFormvbagent.patchValue({
              vbagentnameauto: this.vbagentsdropdown[0].displayname
            })
          }
        }
      }
    }
  }


  //file extensions
  selectedextensionItems: any = [];
  dropdownList: any = [
    { item_id: 1, item_text: ".hl7" },
    { item_id: 2, item_text: ".xml" },
    { item_id: 3, item_text: ".dot" },
    { item_id: 4, item_text: ".txt" },
    { item_id: 5, item_text: ".json" },
    { item_id: 6, item_text: ".pdf" }
  ];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "item_id",
    textField: "item_text",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  onItemSelect(item: any) {
    this.selectedextensionItems = this.selectedItems;
  }

  onSelectAll(items: any) {
    this.selectedextensionItems = items;
  }

  //route Notes Popup
  UniqueID: any = '';
  openNotepopup() {

    if (this.selectedrouteId != '') {
      const noteDataToModal = {
        routeid: this.UniqueID,
        routename: this.selectedrouteDisplayName,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };


      const modalRef = this.modalService.open(RouteNotesComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = noteDataToModal;
      modalRef.result.then(() => { });
    }

  }

  //Audit Details Pop up

  openAuditPopUp() {

    if (this.selectedrouteId != '') {
      const auditDataToModal = {
        id: this.UniqueID,
        title: this.selectedrouteDisplayName,
        context: "Pipeline",
        type: "ID",
        guid: this.selectedrouteId,
        routename: this.selectedrouteDisplayName,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };
      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }
  async getvendarsName(event) {
    var vendarvalue = event;
    this.vendarnamevalue = '';
    if (vendarvalue != '') {
      this.isLoadingvendar = false;
      if (this.VendarsList.length > 0) {
        this.filtervendorlist(vendarvalue);
      }
      else {
        this.VendarsList = this.VendornameBackuplist;
        this.filtervendorlist(vendarvalue);
      }
    }
  }
  suggestedroutename: string = ''
  searchvendarNameCleared(event) {
    this.reactiveFormprojectname.setValue({ "projectname": '' });
    this.isLoadingvendar = false;
    this.projectnamelist = [];
    this.vendarnamevalue = "";
    this.vendorid = "";
    this.VendarsList = this.VendornameBackuplist;
    this.reactiveFormvendar.setValue({ "namevendar": '', "vendorid": '' });
    this.RouteNameSuggest[3]["value"] = '';
    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });
    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
  }
  async selectedvendarEvent(event) {
    this.vendarnamevalue = event.name;
    this.vendorid = event.Id;
    if (this.interfacetype == 'Inbound' && this.selectedtargetaccountid && this.modelradio['option'] == 'Project') {
      this.getprojectname();
    }
    else if (this.interfacetype == 'Outbound' && this.selectedsourceaccountid && this.outboundmodelradio['option'] == 'Project') {
      this.getprojectname();
    }
    this.RouteNameSuggest[3]["value"] = (event.mnemonic == null || event.mnemonic == '') ? event.name : event.mnemonic;
    this.vendormnemonic = (event.mnemonic == null || event.mnemonic == '') ? event.name : event.mnemonic;
    let myArray = [];
    this.RouteNameSuggest.forEach(element => {
      if (element.value != '') {
        myArray.push(element.value);
      }
    });

    this.reactiveFormroute.patchValue({
      routename: myArray.map(element => element).join(".")
    });
    this.suggestedroutename = this.reactiveFormroute.value.routename;
    // select connectivity based on the vendor mapping
    let vendorObj = {
      name: this.vendarnamevalue,
    }
    this.ngxUiLoaderService.start();
    this.modelradio = {};
    this.outboundmodelradio = {};
    if (this.ProcessorType == 'L2L') {
      this.ngxUiLoaderService.stop();
      this.outboundmodelradio.option = 'None';
      this.modelradio.option = 'None';
    }
    else {
      await this.service.postapi("api/Routes/GetLastRouteConnectivityByVendor", vendorObj).then(Response => {
        if (Response) {
          if ((Response['status'] && Response['statusText']) || Response['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            this.ngxUiLoaderService.stop();
            let response = Response as any;
            if (response['status'] == 'success') {
              if (this.interfacetype == "EllkayInbound" || this.interfacetype == "Inbound") {
                this.modelradio.option = response['responsebody'];
              }
              if (this.interfacetype == "EllkayOutbound" || this.interfacetype == "Outbound") {
                this.outboundmodelradio.option = response['responsebody'];
              }
            }
          }
        }
        else {
          this.ngxUiLoaderService.stop();
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      })
    }
  }

  clearftppath() {
    this.ftpexistingpath = '';
    this.resultsftpchecknone = false;
    this.apiconnectivity = false;
    this.vpncheck = false;
  }


  clearftpnonepath() {
    this.ftpexistingpath = '';
    this.ftpcheckexsting = false;
    this.apiconnectivity = false;
    this.vpncheck = false;
  }
  clearapiconnectivity() {
    this.ftpexistingpath = '';
    this.ftpcheckexsting = false;
    this.resultsftpchecknone = false;
    this.vpncheck = false;
  }
  clearvpn() {
    this.ftpcheckexsting = false;
    this.resultsftpchecknone = false;
    this.apiconnectivity = false;
  }

  interfacemappingcheck(event) {
    this.copyinterfacemappings = event.checked;
  }
  //copy pipeline enhance
  oldmyFlagForSlideToggle: boolean = false;
  copypipelineflag: boolean = false;
  oldselectedrouteDisplayName: string = '';
  copyinterfacemappings: boolean = true;
  resetflag: boolean = false;
  copypiprlinrbutton: boolean = false;
  oldmodeSelected: any;
  oldUniqueID: any = '';
  oldvendarnamevalue: string = "";
  oldselectedprocessagentid: string = "";
  oldselectedprocessdisplayname: string = "";
  oldselectedprocessapikey: string = "";
  oldselectedrouteId: string = "";
  olddynamicJSON: inputField[] = [];
  oldvendorid: string;
  async copypipelineevent() {

    this.oldselectedrouteId = this.selectedrouteId;
    this.oldselectedprocessapikey = this.selectedprocessapikey;
    this.oldselectedprocessagentid = this.selectedprocessagentid;
    this.oldselectedprocessdisplayname = this.selectedprocessdisplayname;
    this.oldselectedrouteDisplayName = this.selectedrouteDisplayName;
    this.oldmodeSelected = this.modeSelected;
    this.oldUniqueID = this.UniqueID;
    this.olddynamicJSON = this.dynamicJSON;
    this.oldmyFlagForSlideToggle = this.myFlagForSlideToggle;
    this.oldvendarnamevalue = this.vendarnamevalue;
    this.oldvendorid = this.vendorid;
    await this.Resetalldata();
    var dataitem = {
      apikey: this.oldselectedprocessapikey,
      id: this.oldselectedprocessagentid,
      displayname: this.oldselectedprocessdisplayname,
      processortype: this.ProcessorType
    }

    await this.selectedProcessEvent(dataitem, this.olddynamicJSON[0], '');
    this.oneditftpradio = false
    this.selectedprocessapikey = this.oldselectedprocessapikey;
    this.selectedprocessagentid = this.oldselectedprocessagentid;
    this.selectedprocessdisplayname = this.oldselectedprocessdisplayname;
    this.selectedrouteDisplayName = this.oldselectedrouteDisplayName;
    this.modeSelected = this.oldmodeSelected;
    this.reactiveFormmode.patchValue({
      modename: this.modeSelected
    });
    this.UniqueID = this.oldUniqueID;
    this.dynamicJSON = this.olddynamicJSON;
    this.myFlagForSlideToggle = false;
    this.vendarnamevalue = this.oldvendarnamevalue;
    this.vendorid = this.oldvendorid;
    if (this.vendarmsgdiv) {
      this.reactiveFormvendar.patchValue({
        namevendar: this.vendarnamevalue,
        vendorid: this.vendorid
      })
    }
    this.resetflag = true;
    this.copypipelineflag = true;
  }

  clearvaluesforcopyroute() {
    this.searchSourceNameCleared(event);
    this.searchTargetNameCleared(event);
    this.reactiveFormsource.reset();
    this.reactiveFormtarget.reset();

  }
  async ResetPipelineDetails() {
    this.copypipelineflag = false;
    this.oldselectedrouteDisplayName = '';
    this.resetflag = false;
    this.copypiprlinrbutton = true;
    this.selectedrouteId = this.oldselectedrouteId;
    await this.getRouteDetails();
  }

  //va rule and handler

  openvarulepopup() {
    var dpkeyname = '';
    if (this.interfacetype == "Inbound") {
      dpkeyname = this.targetdeployementkey;
    }
    else {
      dpkeyname = this.sourcedeployementkey;
    }

    let dataToruleModal = {
      routename: this.selectedrouteDisplayName,
      islocked: this.isLocked,
      deploymentkey: dpkeyname,
      contextid: this.UniqueID
    };
    if (this.sourcetypedisplay == 'O') {
      dataToruleModal["LabOrgId"] = this.selectedsourceaccountid;
    }
    else if (this.sourcetypedisplay == 'A') {
      dataToruleModal["LabId"] = this.selectedsourceaccountid;
    }

    if (this.targettypedisplay == 'O') {
      dataToruleModal["OrdFacOrgId"] = this.selectedtargetaccountid;
    }
    else if (this.targettypedisplay == 'A') {
      dataToruleModal["OrdFacilityId"] = this.selectedtargetaccountid;
    }
    else if (this.targettypedisplay == 'F') {
      dataToruleModal["OrdFacilityId"] = this.selectedtargetaccountid;
    }
    const modalRef = this.modalService.open(VarulesComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToruleModal;
    modalRef.result.then(() => { });
  }


  openFTPfilespopup() {
    const dataToFTPFilesModal = {
      routeId: this.selectedrouteId,
      sourceagent: this.envSourceProxy,
      name: this.selectRouteNameBackup,
      uniqid: this.UniqueID,
      selectflag: false,
      connectivitytype: this.connectivitytype
    };
    const modalRef = this.modalService.open(FtpfilesComponent,
      { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToFTPFilesModal;
    modalRef.result.then(() => { });
  }


  getCustomeWidth() {
    if (this.isShown) {
      return 68;
    }
    else {
      return 80;
    }
  }
  buildpiplinetitle(name, id) {
    if (name !== undefined && name != '') {
      if (name.includes("(")) {
        return name
      } else {
        let fullname = name + '(' + id + ')';
        if (id) {
          return fullname;
        }
        else {
          return
        }
      }
    }

  }
  buildtitle(Obj: any, deployment: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}) : ${Obj.deploymentkey} : ${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }

    else if (typeof (Obj) == "string" && Obj != null) {
      switch (deployment) {
        case 'source':
          if (this.selectedsourceaccountName) {
            let sename = `(${this.sourcetype == 'Account' ? 'A' : this.sourcetype == 'Organization' ? 'O' : this.sourcetype}) : ${this.sourcedeployementkey} : ${this.selectedsourceaccountName}(${this.selectedsourceaccountid})
          ${this.sourceentityaddress1}
          `
            return sename;
          }
          break;
        case 'target':
          if (this.selectedtargetaccountName) {
            let dename = `(${this.targettype == 'Account' ? 'A' : this.targettype == 'Organization' ? 'O' : this.targettype}) : ${this.targetdeployementkey} : ${this.selectedtargetaccountName}(${this.selectedtargetaccountid})
        ${this.targetentityaddress1}
        `
            return dename;
          }

          break;
      }
    }
  }
  async getFileConfig(routeid: string, ProcessorId: string) {
    var dataPost = {
      agentid: ProcessorId,
      guids: routeid,
    };
    return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);

  }
  buildProcessorTitle(Obj: any) {
    if (typeof (Obj) == "string" && Obj != null) {
      if (Obj.includes("(")) {
        return Obj
      } else {
        return Obj + "(" + this.selectedprocessagentid + ")"
      }
    }
  }


  //////////multi processor
  dynamicJSON: inputField[] = [];

  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = names[2] !== 'null)' && names[2] !== undefined ? `${names[0]} <br> (${names[1]} <br> (${names[2]}` : `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  addcontrols(obj: inputField[]) {
    if (this.dynamicJSON.length == 1) {
      this.dynamicJSON[0].data = this.ProcessorAllList;
    }
    let controlname = "prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1);
    let reactiveFormprocess: FormGroup = this._fb.group({ ["prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1)]: '' });
    let inputcnt: inputField = {
      data: this.ProcessorAllList,
      formcontrolname: controlname,
      formgroupname: reactiveFormprocess,
      sequence: this.dynamicJSON.length + 1,
      mapicon: false,
      islive: false
    }
    this.dynamicJSON.push(inputcnt);
    if (!this.dynamicJSON[this.dynamicJSON.length - 1].islive) {
      this.routeconfigflag = true;
      this.myFlagForSlideToggle = false;
    }
  }

  async InitilizeProcessordata() {
    var apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';
    let resultset = await this.service.getapi(apiname);
    resultset.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}` });
    this.ProcessorAllList = resultset;
    this.setfirstcontrol()
    return resultset;
  }

  deleteControl(array, i) {
    array.splice(i, 1);
    this.dynamicJSON = [];
    array.forEach(element => {
      let controlname = '';
      let reactiveFormprocess: FormGroup;
      if (this.dynamicJSON.length == 0) {
        controlname = "prop";
        reactiveFormprocess = this._fb.group({ ["prop"]: element.formgroupname.controls[element.formcontrolname].value });
      }
      else {
        controlname = "prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1);

        reactiveFormprocess = this._fb.group({ ["prop" + (this.dynamicJSON[this.dynamicJSON.length - 1].sequence + 1)]: element.formgroupname.controls[element.formcontrolname].value });
      }
      let inputcnt: inputField = {
        data: this.ProcessorAllList,
        formcontrolname: controlname,
        formgroupname: reactiveFormprocess,
        sequence: this.dynamicJSON.length + 1,
        mapicon: element.mapicon,
        islive: element.islive
      }
      this.dynamicJSON.push(inputcnt);
    });
    let exists = this.dynamicJSON.filter(ele => ele.islive === false);
    if (exists.length == 0) {
      this.routeconfigflag = false;
    }
    else {
      this.routeconfigflag = true;
      this.myFlagForSlideToggle = false;
    }
  }

  async setfirstcontrol() {
    this.dynamicJSON = [];
    if (this.dynamicJSON.length == 0) {
      let reactiveFormprocess: FormGroup = this._fb.group({ "prop": '' });
      let inputcnt: inputField = {
        data: this.ProcessorAllList,
        formcontrolname: "prop",
        formgroupname: reactiveFormprocess,
        sequence: 1,
        mapicon: false,
        islive: false
      }

      this.dynamicJSON.push(inputcnt);
    }
  }
  getcoreconfigforroute() {
    this.ngxUiLoaderService.start();
    var dataPost = {
      guids: this.selectedrouteId,
      filtertype: 'Pipeline'
    };
    this.ngxUiLoaderService.start();
    this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost).then(Response => {
      if (Response) {
        if ((Response['status'] && Response['statusText']) || Response['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else {
          this.RouteConfigList = Response as any[];
          this.RouteConfigBackUpList = this.RouteConfigList;
          if (this.RouteConfigList.length > 0) {
            this.filteragentlist = [];
            this.filteragentlistbackup = [];
            this.RouteConfigList.forEach(ele => {
              ele['isLocked'] = this.isLocked
              let obj = {
                name: ele.agentdisplayname,
                displayname: `${ele.agentdisplayname}(${ele.agentid})`,
                id: ele.agentid,
              }
              this.filteragentlist.push(obj);
            });
            this.filteragentlist = [...new Map(this.filteragentlist.map(item =>
              [item["id"], item])).values()];
            this.filteragentlistbackup = this.filteragentlist;
            this.reactiveFormAgentFilter.setValue({ "filteragent": "" })
            this.editconfigjson(this.RouteConfigList[0], this.RouteConfigList)
            this.ngxUiLoaderService.stop();
          }
          this.ngxUiLoaderService.stop();
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    })
  }
  registerMonacoCustomTheme() {
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs", // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: "comment",
          foreground: "ffa500",
          fontStyle: "italic underline"
        }, {
          token: "comment.js",
          foreground: "008800",
          fontStyle: "bold"
        }, {
          token: "comment.css",
          foreground: "0000ff"
        }, // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }
  editconfigjson(data, array) {
    array.forEach(ele => {
      ele["active"] = false;
    })
    this.configjson = data.json;
    if (data.json != "{}" && data.json != '') {
      data["active"] = true;
      this.configjson = JSON.stringify(JSON.parse(data.json), null, 2);
    }
    this.configid = data.configid;
    this.configagentid = data.agentid;
    this.configguids = data.guids;
    this.configagentapikey = data.agentapikey;
    this.configstatus = data.status;

  }
  configdetails() {
    this.enableconfig = true;
    this.ngxUiLoaderService.start();
    this.getcoreconfigforroute();
    // this.ngxUiLoaderService.stop();
  }
  clearconfigdetails() {
    this.enableconfig = false;
    this.RouteConfigList = [];
    this.filteragentlist = [];
    this.filteragentlistbackup = [];
    this.RouteConfigBackUpList = [];
    this.configid = "";
    this.configjson = "";
    this.configagentid = "";
    this.configguids = '';
    this.configstatus = '';
  }
  getSourceAgentName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceAgentNameList = data as any[];
          this.SourceAgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'}`);
          this.isLoadingagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  getTargetAgentName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingTargetagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.TargetAgentNameList = data as any[];
          this.TargetAgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'}`);
          this.isLoadingTargetagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingTargetagent = false;
          this.notfounddata = true;
        }
      });
    }
  }

  GetMessageTypes(event) {

    if (event !== 'msgtype') {
      this.isLoadingstage = true;
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {
      this.isLoadingstage = true;
      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingstage = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
      });
    }
  }
  selectedsourceagent(item) {
    this.selectedsourceagentid = item.id;
    this.selectedsourceapikey = item.apikey;
  }
  selectedsourceagentcleared() {
    this.isLoadingTargetagent = false;
    this.SourceAgentNameList = [];
    this.selectedsourceagentid = '';
    this.selectedsourceapikey = '';
  }

  selectedtargetagent(item) {
    this.selectedtargetagentid = item.id;
    this.selectedtargetapikey = item.apikey;
  }
  selectedtargetagentcleared() {
    this.isLoadingagent = false;
    this.TargetAgentNameList = [];
    this.selectedsourceagentid = '';
    this.selectedsourceapikey = '';
  }


  async MatchingVBJobs(JobType) {
    let dataToFTPFilesModal = {
      vbagentid: this.envSourceProxy,
      sourceaccountid: this.selectedsourceaccountid,
      targetaccountid: this.selectedtargetaccountid,
      messagetype: this.messagetype,
      routename: this.selectedrouteDisplayName,
      jobidmapped: this.selectedjobId,
      routeid: this.selectedrouteId,
      JobType: JobType,
      data: JobType == 'Device' ? this.savedjobdetails : ''
    };
    const modalRef = this.modalService.open(VbjobsdisplayComponent,
      { windowClass: "myCustomModalClassVB", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToFTPFilesModal;
    modalRef.result.then(() => { });


  }
  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.VendarsList = data as any[];
        this.VendarsList = this.service.transformAlphabetically(this.VendarsList, 'name');
        this.VendornameBackuplist = this.VendarsList;
        this.ngxUiLoaderService.stop();
        this.reactiveFormvendar.setValue({ "namevendar": '', "vendorid": '' });
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  filtervendorlist(event) {
    this.VendarsList = this.VendarsList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.VendarsList.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendar = false;
  }
  openvendorpopup() {
    let obj = {
      data: '',
      vendornamelist: this.VendornameBackuplist,
      component: 'createpipeline',
      Type: "Add",
      vendorname: this.reactiveFormvendar.get('namevendar').value
    }

    const modalRef = this.modalService.open(SavevendorComponent,
      { windowClass: "saveVendormodal", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendar.patchValue({
          namevendar: data.vendorname
        });
        this.vendarnamevalue = data.vendorname;
      }
    });
  }


  keyword = 'name';
  public modeItemsList = [
    {
      id: 'Hold',
      name: 'Hold',
    },
    {
      id: 'Live',
      name: 'Live',
    },
    {
      id: 'Test Live',
      name: 'Test Live',
    }
  ];

  selectmodeEvent(item) {
    this.modeSelected = item.name;
  }
  onFocusedmode(event) {
    this.reactiveFormmode.setValue({ "modename": '' });
    this.modeItemsList = [
      {
        id: 'Hold',
        name: 'Hold',
      },
      {
        id: 'Live',
        name: 'Live',
      },
      {
        id: 'Test Live',
        name: 'Test Live',
      }
    ];
    this.reactiveFormmode.patchValue(this.modeItemsList);
  }

  clearedmode(event) {
    this.reactiveFormmode.reset();
    this.reactiveFormmode.setValue({ "modename": '' });
    this.modeItemsList = [
      {
        id: 'Live',
        name: 'Live',
      },
      {
        id: 'Test Live',
        name: 'Test Live',
      },
      {
        id: 'Hold',
        name: 'Hold',
      }
    ];
    this.reactiveFormmode.patchValue(this.modeItemsList);
  }

  isLoadingvbagent: boolean = false;
  selectedvbagentevent(item) {
    this.modelvbclient.vbclientagnetid = item.VBAgentID
  }
  Clearevbagent(event) {

  }
  getvbagentlist(event) {

  }
  openconfigpopup(isflag: string, type: string, item) {
    this.ngxUiLoaderService.start();
    if (isflag == "small") {
      let data = {};
      if (type == "create") {
        data = {
          pipelineid: this.selectedrouteId,
          pipelinedisplayname: this.selectedrouteDisplayName,
          editjson: "small",
          type: "create",
          configlist: this.RouteConfigBackUpList,
          disablepipeline: true,
          pipelineuniqueid: this.UniqueID
        }
      }
      else {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        if (item["guids"]) {
          data["pipelineid"] = item.guids;
        }
        else {
          data["guids"] = '';
        }
        data["agentdisplayname"] = item.agentdisplayname;
        data["pipelinedisplayname"] = item.pipelinedisplayname;
        data["editjson"] = "small";
        data["type"] = "Edit";
        data["disablepipeline"] = true;
        data["status"] = item.status;
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = item.agentapikey
      }
      this.modalService.dismissAll()
      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        modalRef.close()
        // if (data == "refresh") {
        this.ngxUiLoaderService.start();
        this.configagentid = "";
        this.configid = "";
        this.configjson = "";
        this.configstatus = "";
        this.configagentapikey = '';
        this.getcoreconfigforroute();
        this.ngxUiLoaderService.stop();
        // }
      }, (dat) => {

        modalRef.close()
      });
    } else {
      let data = {
        editjson: "big",
        type: type
      }
      if (type != "create" && item == '') {
        data["configid"] = this.configid;
        data["agentid"] = this.configagentid;
        data["json"] = this.configjson;
        data["guids"] = this.configguids ? this.configguids : ''
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = this.configagentapikey;
        data["status"] = this.configstatus;
      }
      else if (type != "create" && item != '') {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        data["guids"] = item.guids;
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = item.agentapikey;
        data["status"] = item.status;
      }
      this.activeModal.close()
      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfigJSON", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.configagentid = "";
          this.configid = "";
          this.configjson = "";
          this.configstatus = "";
          this.configagentapikey = '';
          this.getcoreconfigforroute();
          this.ngxUiLoaderService.stop();
        }
      }, (resile) => {

        modalRef.close()
      });
    }
    this.ngxUiLoaderService.stop();
  }
  getFilteredValues(event) {
    if (event) {
      this.isLoadingFilteredValue = true;
      this.ngxUiLoaderService.start();
      if (this.filteragentlist.length == 0) {
        this.filteragentlist = this.filteragentlistbackup;
      }
      let isfound = false;
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(event)) {
        isfound = true;
        this.filteragentlist = this.filteragentlist.filter(x => x.id == event);
      }
      else {
        isfound = true;
        this.filteragentlist = this.filteragentlist.filter(x => x.name.toLowerCase().includes(event.toLowerCase()));
      }
      if (isfound) {
        this.configagentid = "";
        this.configguids = "";
        this.configid = "";
        this.configjson = "";
        this.configstatus = "";
        if (this.filteragentlist.length == 0) {
          this.notfounddata = true;
        }
        this.isLoadingFilteredValue = false;
      }
      this.ngxUiLoaderService.stop();
    }
  }
  selectedFilteredValues(item) {
    if (item) {
      this.RouteConfigList = this.RouteConfigList.filter(x => x.agentid == item.id);
    }
  }
  searchFilteredValuesCleared(item) {
    if (this.RouteConfigList.length != this.RouteConfigBackUpList.length) {
      this.configagentid = "";
      this.configguids = "";
      this.configid = "";
      this.configjson = "";
      this.configstatus = '';
      this.RouteConfigList = this.RouteConfigBackUpList;
    }
    this.filteragentlist = this.filteragentlistbackup;
    this.notfounddata = false;
    this.reactiveFormAgentFilter.reset();
    this.reactiveFormAgentFilter.setValue({ "filteragent": "" })
  }
  ngOnDestory() {
    this.isflasgSize.unsubscribe()
    this.vpncheck = false
    this.oneditftpradio = false
    this.vpndetails = false
  }

  async gethandlerrulestatus() {
    //this.ngxUiLoaderService.start();
    if (this.deploymentsConfigurations != undefined) {
      var deploymentleveconfigs = this.deploymentsConfigurations;
      var res;
      var dpkey;
      if (this.interfacetype == "Outbound" || this.interfacetype == "EllkayOutbound" || this.interfacetype == "ResultOutbound") {
        res = deploymentleveconfigs[this.targetdeployementkey];
        dpkey = this.targetdeployementkey;
        if (this.sourcedeployementkey !== this.targetdeployementkey) {
          dpkey = this.sourcedeployementkey;
        }
      }
      else {
        if (deploymentleveconfigs !== undefined) {
          res = deploymentleveconfigs[this.sourcedeployementkey];
        }
        dpkey = this.sourcedeployementkey;
      }
      var api = "api/VitalHubDXAPI/gethandlerandrule?groupid=" + this.selectedrouteId;
      await this.service.dxgetapi(api, dpkey).subscribe({
        next: data => {
          this.additionalinfodataflag = true;
          if (this.interfacetype == "EllkayInbound" || this.interfacetype == "Inbound") {
            this.handlercreateddisplaylabel = true;
            this.rulecreateddisplaylabel = false;
            if (this.modelradio["option"] != "API" && this.modelradio["option"] != "VPN" && this.modelradio["option"] != "None") {
              this.vbjobcreateddisplaylabel = true;
            }

            if (this.modelradio["option"] == 'FTP') {
              this.ftpcreateddisplaylabel = true;
            }
          }
          else if (this.interfacetype == 'EllkayOutbound' || this.interfacetype == 'Outbound') {
            this.handlercreateddisplaylabel = true;
            this.rulecreateddisplaylabel = true;
            if (this.connectivitytype == 'API') {
              this.ftpcreateddisplaylabel = false;
            }
            else {
              this.ftpcreateddisplaylabel = true;
            }
            this.vbjobcreateddisplaylabel = false;
          }

          if (data["Handler"] !== '' && data["Handler"] !== null) {
            this.handlercreatedflag = true;
          }
          else {
            this.handlercreatedflag = false;
          }

          if (data["Handler"] !== '' && data["VaRule"] !== '' && data["Handler"] !== null && data["VaRule"] !== null) {
            this.rulecreatedflag = true;
          }
          else {
            this.rulecreatedflag = false;
          }
          if (data["Description"] !== '' && data["Handler"] !== null) {
            this.Descriptionflag = true;
            this.Description = data["Description"];

          }
          // this.ngxUiLoaderService.stop();
        },
        error: error => {
          this.additionalinfodataflag = false;
          // this.toastr.error("failed to get pipeline status", "",
          //   { timeOut: 4000, positionClass: 'toast-bottom-right' });
          this.ngxUiLoaderService.stop();
        }
      });;
    }
  }
  selectedmessagetypeevent(event) {
    this.messagetype = event.displayname;
  }

  Clearemessagetype() {
    this.messagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }
  selectedRoutePolicyEvent(item) {
    this.routepolicyselected = item.value;
  }
  clearedroutepolicy() {
    this.routepolicyselected = "";
  }
  async addinterfacedescription(c) {
    if (this.selectedInterfacedescription) {
      c('close modal');
      if (this.handlerSpinner) {
        var deploymentleveconfigs = this.deploymentsConfigurations;
        this.createhandlerandrule(deploymentleveconfigs, this.targetdeployementkey, true);
        return
      }
      if (this.ruleSpinner) {
        var deploymentleveconfigs = this.deploymentsConfigurations;
        this.createhandlerandrule(deploymentleveconfigs, this.targetdeployementkey, true);
        return
      }

      await this.SaveRouteDetailstoDB();
      this.selectedInterfacedescription = "";
    }
    else {
      this.toastr.warning("Please provide the notes!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
  }
  closeinterfacedescriptionpopup(c) {
    this.handlerSpinner = false;
    this.ruleSpinner = false;
    this.selectedInterfacedescription = "";
    c('close modal');
  }

  async createFTP() {
    await this.getrandamusernamepassword();

    // call create FTP credentials and update ftp config
    const postentity: any = {
      "UserName": this.ftpusername,
      "Password": this.ftppassword,
      "RemoteSitePath": this.selectedrouteDisplayName, //will create ftp folder with route name
      "FTPpath": '',
      "Deploymentkey": this.sourcedeployementkey,
      "ContextId": this.UniqueID,
      "AgentId": this.envSourceProxy,
      "ConnectivityType": this.modelradio["option"],
      "Context": "pipeline",
      "ModifierID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "Suffix": this.suffixfromprocessor,
      "FolderWatchFlag": true,
      "MessageTypes": this.messagetype,
      "Directions": this.interfacetype,
      "TypeFlag": "Interface"
    }
    var ftpresponse = await this.service.postapi("api/FTP/CreateAndSaveFTP", postentity);
    if (ftpresponse['statusmessage'] == 'Success') {
      this.ftpflag = true;
      this.ftpalreadycreated = false;
      this.updateconfigflagftp = false;
      this.ftpusername = ftpresponse['UserName'];
      this.ftphost = ftpresponse['ServerDomain'];
      this.ftpport = ftpresponse['Port'];
      this.remotepathdisplay = ftpresponse['RemoteSitePath'];
      if (this.modelradio["option"] == "FTPV2") {
        this.ftppassword = ftpresponse['Password']
        this.ftphost = "sftp://" + ftpresponse['ServerDomain'];
        this.ftpcreatedflag = true;
        //ftpresponse['FullPath']=ftpresponse['RemoteSitePath'];
      }
      await this.getftppaths(ftpresponse['FullPath']);

    }
    else {
      this.ftpflag = false;
      this.ftpalreadycreated = true;
      this.updateconfigflagvb = false;
      this.ngxUiLoaderService.stop();

      this.toastr.error('Pipeline saved successfully but failed to create FTP user', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
  }

  async SaveVbJobConfig(createjobresponse) {
    if (createjobresponse.status == "success") {
      this.vbflag = true;
      this.updateconfigflagvb = false;
      this.selectedjobId = createjobresponse.jobid;
      this.selectedjobName = createjobresponse.jobname;
      this.isShownorderinbound = true;
      this.isShownorderinboundvb = true;
      this.isShownresultoutbound = false;
      this.VBClientmatcard = false;
      if (this.modelradio["option"] == 'VB Client') {
        this.vbfolderpathflag = true;
        this.vbfolderpathdisplay = this.modelvbclient.vbclientpath;
      }
      else {
        this.vbfolderpathflag = false;
      }
    }
  }


  async SaveUploadOrDownloadVBJob(JobType, Connectivitytype) {
    //call the VB api to create the job
    this.vbreturndetails = {
      "status": "",
      "statuscode": ''
    };

    this.vbFTPFileJob["JobName"] = this["selectedrouteDisplayName"] + '_External';
    var extensionsmulti = '';
    this.selectedextensionItems.forEach(element => {
      extensionsmulti = element.item_text;
      this.extensions.push(extensionsmulti)
    });

    if (this.selectedextensionItems == '') {

    }
    else {
      this.vbFTPFileJob["FileExtensionsList"] = this.extensions;
    }

    this.vbFTPFileJob["RemoteArchiveFolder"] = JobType == "FTP5FileWatchUpload" ? this.archivepath + "\\" : this.customftp.archivepath;
    this.vbFTPFileJob["LocalDownloadFolder"] = this.sourcepath;
    this.vbFTPFileJob["Downloadsource"] = this.customftp.folder;
    this.vbFTPFileJob["Host"] = this.customftp.host;
    this.vbFTPFileJob["Port"] = this.customftp.port;
    this.vbFTPFileJob["Username"] = this.customftp.username;
    this.vbFTPFileJob["Password"] = this.customftp.password;
    this.vbFTPFileJob["OrgID"] = this.selectedsourceaccountid;
    this.vbFTPFileJob["RouteDefinitionId"] = this.selectedrouteId;
    this.vbFTPFileJob["AgentId"] = this.envSourceProxy;
    this.vbFTPFileJob["Status"] = this.myFlagForSlideToggle ? "Start" : "Stop";
    this.vbFTPFileJob["CreatedBy"] = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
    this.vbFTPFileJob["JobType"] = JobType;
    this.vbFTPFileJob["Connectivitytype"] = Connectivitytype;
    this.vbFTPFileJob["UniqueID"] = this.UniqueID;
    this.vbFTPFileJob["FileWatchMessageType"] = this.messagetype;
    this.vbFTPFileJob["SourceEntityId"] = this.selectedsourceaccountid;
    this.vbFTPFileJob["DestinationEntityId"] = this.selectedtargetaccountid;

    let response = await this.service.postapi('api/VBJobs/SaveVBFileJob', this.vbFTPFileJob);
    if (response.status == 'success') {
      this.vbflag = true;
      this.vbreturndetails.status = 'success';
      this.vbreturndetails.jobid = response.content["jobid"];
      this.vbreturndetails.jobname = response.content["jobname"];
    }


    return this.vbreturndetails;
  }

  async savecustomFTPDetails() {
    this.ngxUiLoaderService.start();
    const ftpDetails = {
      id: this.customftp.id ? this.customftp.id : 0,
      contextId: this.UniqueID,
      context: 'pipeline-external',
      agentId: this.envSourceProxy,
      connectivityType: this.interfacetype == 'Outbound' ? this.outboundmodelradio["option"] : this.modelradio["option"],
      connectionType: "sftp",
      host: this.customftp.host.replace('sftp://', ''),
      port: parseInt(this.customftp.port),
      server: this.customftp.host.replace('sftp://', ''),
      userName: this.customftp.username,
      password: this.customftp.password,
      remotePath: this.customftp.folder.replace('\\', '//'),
      sourcePath: this.customftp.folder,
      archivePath: this.customftp.archivepath,
      progressPath: '',
      errorPath: '',
      pipelineName: this.selectedrouteDisplayName,
      folderWatchFlag: false,
      modifierId: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']

    }

    //call ftp config update api
    var responseconfig = await this.service.postapi('api/CoreConfig/SaveFtpDetails', ftpDetails);
    if (responseconfig['status'] && responseconfig['statusText']) {
      this.updateconfigflagvb = false;
      this.ngxUiLoaderService.stop();
      this.toastr.error('Failed to Update FTP configuration in Flat Table' + responseconfig['statusText'], "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
  }

  UpdateConnectivityDetails() {
    var apiname = 'api/Routes/UpdateConnectivityDetails?RouteId=' + this.selectedrouteId;
    this.service.getapi(apiname);
  }

  getprojectname() {
    let obj = {
      "searchinput": null,
      "vendorId": this.vendorid,
      "labGuid": this.interfacetype == 'Inbound' ? this.selectedtargetaccountid : this.selectedsourceaccountid
    }
    this.service.getprojects(obj).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success' && data['statusmessage'].toLowerCase() != 'no data found') {
        this.notfounddata = false;
        this.projectnamelist = JSON.parse(data['statusmessage']);
        this.projectnamelist.map(obj => obj['displayname'] = `${obj.projectName} ${'(' + obj.projectId + ')'}`);
        this.projectnameBackuplist = this.projectnamelist;
      }
      else {
        this.notfounddata = true;
        this.toastr.warning('No projects found', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    })
  }

  inputprojectname(event: any) {
    let value;
    if (event != undefined) {
      value = event.projectName
      this.projectnamelist = this.projectnamelist.filter(t => t.displayname.toLowerCase().includes(value.toLowerCase()));
    }
    if (this.projectnamelist.length == 0) {
      this.notfounddata = true;
    } else {
      this.notfounddata = false;
    }
    this.isLoadingProject = false;
  }


  selectedprojectEvent(item) {
    this.id = item.id
    this.selectedprojectId = item.projectId;
    this.selectedprojectname = item.projectName;
    this.pipelineselectclass = 'ng-autocompleteselect';
    this.isLoadingProject = false;
  }
  async searchprojectCleared() {
    if (this.selectedprojectId !== "") {
      this.projectnamelist = []
      this.projectnamelist = this.projectnameBackuplist;
      this.isLoadingProject = false;
    }
  }
  projectfullname(id, name) {
    return '(' + id + ')' + ' ' + name
  }
  saveprojectftp(ftpprojobj: any) {
    this.service.saveprojftpdetails(ftpprojobj).subscribe((data) => {
      if (data['progressPath'] != "" || data['progressPath'] != null) {
        this.ftpflag = true;
        this.ftpalreadycreated = true;
        this.ftpusername = data['userName'];
        this.ftppassword = data['password']
        this.ftphost = data['host'];
        this.ftpport = data['port'];
        this.remotepathdisplay = data['remotePath'];
        return
      }
      else {
        this.ftpflag = false;
        return
      }
    })

  }

  sendftpmail() {
    let obj =
    {
      "projectID": '',
      "projectname": '',
      "interfaceID": this.selectedrouteId,
      "interfacename": this.selectedrouteDisplayName,
      "ftpdata": {
        "Host": this.ftphost,
        "Username": this.ftpusername,
        "Password": this.ftppassword,
        "Port": this.ftpport
      }
    }
    this.ngxUiLoaderService.start();
    this.service.pdf64(obj).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success') {
        this.pdf64responsebody = data['responsebody']
        this.ftpbase64 = 'data:application/pdf;base64,' + data['responsebody'];
        this.filenamedisplay = data['statusmessage'].split(':')[1];
        this.key = data['extendedattributes']['key'];
        let authhtml
        authhtml = `<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />FTP file password enclosed below.</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+ this.key + `</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
        VitalAxis</span></span></span></span></p>`
        this.Subject.setValue("Password to access FTP Details for " + this.selectedrouteDisplayName + "(" + this.selectedrouteId + ")");
        this.mainSubject.setValue("FTP Details for Interface :" + this.selectedrouteDisplayName + "(" + this.selectedrouteId + ")");

        this.htmlContent = `<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`;

        let attachmentobjftp = {}
        attachmentobjftp[this.filenamedisplay] = this.pdf64responsebody
        let pdfMailComponent = {
          responsebody: this.pdf64responsebody,
          base64: this.ftpbase64,
          filenamedisplay: this.filenamedisplay,
          key: this.key,
          toaddresslist: this.alltoaddressid,
          Cclist: this.allCcid,
          mainSubject: this.mainSubject.value,
          htmlContent: this.htmlContent,
          authhtml: authhtml,
          Subject: this.Subject.value,
          attachmentobj: attachmentobjftp
        }
        const modalRef = this.modalService.open(MailComponent,
          { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
        modalRef.componentInstance.inputDataInModalComponent = pdfMailComponent;
        modalRef.result.then(() => { });
        this.ngxUiLoaderService.stop();
        // $("#duplicateModalRight").modal('show').appendTo('body');

        // setTimeout(()=>{
        //   this.tomailInput.nativeElement.focus();
        //   this.CcInput.nativeElement.focus();
        //  })
      } else if (data['status'].toLowerCase() === "failure") {
        this.ngxUiLoaderService.stop();
        this.toastr.error(data.statusmessage, 'Failed', {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

  isdevicename() {
    if (this.modelradio.option == "Device" && this.selectedsourceaccountid == '' && this.selectedtargetaccountid == '') {
      this.isinbounddevice = false;
    }
    else {
      this.isinbounddevice = true;
    }
  }

  isoutbounddevicename() {
    if (this.modelradio.option == "Device" && this.selectedsourceaccountid == '') {
      this.isoutbounddevice = false;
    }
    else {
      this.isoutbounddevice = true;
    }
  }

  getdeviceName(event) {
    let value = event;
    if (value != '') {
      if (this.DeviceList.length > 0) {
        this.filterdevicelist(value);
      }
    }
  }
  filterdevicelist(value) {
    this.DeviceList = this.DeviceList.filter(t => t.name.toLowerCase().includes(value.toLowerCase()));
    if (this.DeviceList.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
  }
  devicenamecleared() {
    this.selecteddevice = ''
    this.DeviceList = this.DeviceListbackup;
    this.reqfieldslist = [];
  }
  devicejobconfigid: any = ''
  deviceremoteip: any = ''
  deviceremoteport: any = ''

  async createdevicejob() {
    let DeviceConnectivityDetails = {
      "DeviceName": this.selecteddevice,
      "RemoteIP": this.deviceremoteip,
      "RemotePort": Math.floor(Math.random() * 100000 + 1),
      "OrderRouteID": this.selectedrouteId,
      "ResultRouteID": this.selectedrouteId
    }
    let jobj = {
      "routeUniqueId": this.UniqueID,
      "agentId": this.selectedvbagentid,
      "status": "active",
      "action": this.myFlagForSlideToggle ? "Start" : "Stop",
      "jobName": this.selectedroutename,
      "jobType": "Device Integration",
      "jobSubType": "HostQuery",
      "accountGuid": this.selectedtargetaccountid,
      "organizationGuid": this.targetorgguid,
      "createdBy": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "json": DeviceConnectivityDetails
    }


    this.service.CreateDeviceIntegrationJob(jobj).subscribe((data) => {
      let response = JSON.parse(data['responsebody']);
      if (response.status == 'success' || response.statuscode == '1002') {
        this.devicejobconfigid = response['content'][0]['configid'];
        this.vbjobcreatedflag = true
        return this.devicejobconfigid
      }
      else {
        this.toastr.error("Failed to Create VB JOB", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return ''
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something went wrong in creating Device job", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }

  async getdevicejobdetails() {
    this.ngxUiLoaderService.start();
    this.isShownorderinbound = false;
    this.service.GetJobDetails(this.envSourceProxy, this.selectedrouteId).subscribe((data) => {
      if (data.status == 'success') {
        let obj = JSON.parse(data['responsebody']);
        if (obj && obj.length > 0) {
          this.savedjobdetails = JSON.parse(obj[0].Json);
          this.VBJobCurrentStatus = obj[0].CurrentState;
          this.vbjobcreatedflag = true;
          this.isShownorderinbound = true;
        }
      }
      else {
        this.toastr.error("Failed to get job details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();

    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something went wrong in getting Job details", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
    return this.vbjobcreatedflag
  }

  async Getvbagentsdetails() {
    if (this.selectedtargetaccountid != '' && this.selectedtargetaccountid != undefined) {
      this.ngxUiLoaderService.start();
      this.service.Getvbagentsdetails(this.selectedtargetaccountid).subscribe((data) => {
        if (data.status == 'success') {
          let obj = JSON.parse(data['responsebody']);
          this.vbagentlist = obj['content'];
          this.vbagentlistbackup = obj['content'];
          this.vbjobcreatedflag = true;
        }
        this.ngxUiLoaderService.stop();
        return this.vbjobcreatedflag;
      }, error => {
        this.ngxUiLoaderService.stop();
      })
    }
  }
  selectvbagent(value) {
    if (value != undefined) {
      this.selectedvbagentname = value.AgentName;
      this.selectedvbagentid = value.AgentID;
    }
  }
  getvbagentName(event) {
    let value = event;
    if (value != '') {
      if (this.vbagentlist.length > 0) {
        this.filtervbagentlist(value);
      }
    }
  }
  filtervbagentlist(value) {
    this.vbagentlist = this.vbagentlistbackup.filter(t => t.AgentName.toLowerCase().includes(value.toLowerCase()));
    if (this.vbagentlist.length == 0) {
      this.notfounddatavbagent = true;
    } else {
      this.notfounddatavbagent = false;
    }
  }
  vbagentnamecleared() {
    this.selectedvbagentname = ''
  }
  async getDevicelistbyentity() {
    let devicedetails = {}
    if (this.interfacetype == 'Inbound') {
      devicedetails = {
        "OrgGUID": this.targetorgguid,
        "Deploymentkey": this.targetdeployementkey,
        "Direction": this.interfacetype
      }
    }

    if (this.selectedtargetaccountid != '' && this.selectedsourceaccountid != '') {
      if (this.rbtn == 'Device') {
        this.ngxUiLoaderService.start();
        this.service.CreateDeviceConnectivity(devicedetails).subscribe((data) => {
          this.ngxUiLoaderService.stop();
          if (data.responsebody == null || data.responsebody == '') {
            this.toastr.warning("No devices", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else {
            this.DeviceList = []
            let dlist = JSON.parse(data.responsebody);
            this.defaultjobjson = data['extendedattributes']
            dlist.forEach(element => {
              let obj = { 'DeviceName': element }
              this.DeviceList.push(obj);
            })
            this.DeviceListbackup = JSON.parse(JSON.stringify(this.DeviceList));
            if (this.interfacetype == 'Inbound') {
              this.isdevicename();
            }
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("something went wrong in getting device list", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        })
      }
    }
  }
  jobschemadefault: any = {}
  selectedjobtemplateid: string = ''
  selectedjobSubType: string = ''
  selectdevice(dname) {
    this.selecteddevice = dname.DeviceName;
    // this.reqfieldslist = [];
    // if (this.jobschema) {
    //   this.customfield = true
    //   this.jobschema['required'].forEach(element => {
    //     for (let [key, value] of Object.entries(this.jobschema['properties'])) {
    //       if (element == key) {
    //         [value][0]['name'] = element;
    //         [value][0]['default'] == undefined ? [value][0]['default'] = '' : [value][0]['default'] = [value][0]['default'];
    //         [value][0]['readonly'] == undefined ? [value][0]['readonly'] = false : [value][0]['readonly'] = true;
    //         if ([value][0]['name'] == 'AccountGuid') {
    //           [value][0]['default'] = this.selectedtargetaccountid
    //         }
    //         [value][0]['name'] == 'JobName' ? '' : this.reqfieldslist.push([value][0]);
    //       }
    //     }
    //   });
    // }
  }
  viewpassword(isftp: boolean) {
    if (isftp && this.isviewpasswordftp) {
      this.service.auditsave("Add", "", "Viewed FTP password for pipeline :- " + this.selectedrouteDisplayName, "Pipeline", this.UniqueID, "Viewed Password (Pipeline)");
    } else if (!isftp && this.isviewpasswordexftp) {
      this.service.auditsave("Add", "", "Viewed External-FTP password for pipeline :- " + this.selectedrouteDisplayName, "Pipeline", this.UniqueID, "Viewed Password (Pipeline)");
    }
  }
  async ADTinterfaces(issave: boolean) {
    let obj = {
      routename: this.selectedroutename,
      sourceguid: this.selectedsourceaccountid,
      deploymentkey: this.sourcedeployementkey,
      oldroutename: this.selectedrname,
      accountid: ""
    }
    let actioname = issave ? "SaveADTInterfaceAccountDetails" : "GetADTInterfaceAccountDetails"
    let response = await this.service.dxpostapi("api/VitalHubDXAPI/" + actioname, obj);
    if (response['status'] == 'success') {
      this.adtflag = true;
    }
    else {
      this.adtflag = false
    }
  }
  async DFTinterface(savefalg: boolean) {
    let obj = {
      sourceguid: this.selectedsourceaccountid,
      deploymentkey: this.sourcedeployementkey,
      username: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      contextid: this.UniqueID
    }
    let actioname = savefalg ? "SaveDftInterfaceDetails" : "GetDftInterfaceDetails"
    let response = await this.service.dxpostapi("api/VitalHubDXAPI/" + actioname, obj);
    if (response['status'] == 'success') {
      this.dftcheck = true;
      this.dftflag = true;
    }
    else {
      this.dftcheck = true;

      this.dftflag = false
    }
  }
  closeadtmodal(adt) {
    adt('close modal');
  }
}

export interface inputField {
  data: any;
  formgroupname: FormGroup,
  formcontrolname: string;
  sequence: number;
  mapicon: boolean;
  islive: boolean;
}
export interface CustomFTPDetails {
  username: string;
  password: string;
  host: string;
  port: string;
  folder: string;
  id: string;
  archivepath: string;
}

